import type { Ad } from '@growthlytic/shared-ad';
import type { HttpResponseEnvelopeJson } from '@growthlytic/shared-common';
import { env } from '../utils/env';
import { httpClient } from '../utils/http-client';

export type GetAdsPayload = {
  accessToken: string;
  adAccountIds: string[];
};

export const getAds = ({
  adAccountIds,
  accessToken,
}: GetAdsPayload): Promise<Ad[]> =>
  httpClient
    .get<HttpResponseEnvelopeJson<Ad[]>>(`${env.service.baseUrl}/v1/ads`, {
      searchParams: { accessToken, adAccountIds: adAccountIds.join(',') },
    })
    .json()
    .then((response) => response.data);
