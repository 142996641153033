import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@growthlytic/web-shared-common';
import type { LucideIcon } from 'lucide-react';

type AdMetricOverviewCardProps = {
  description: string;
  title: string;
  value: string;
  Icon: LucideIcon;
};

function AdMetricOverviewCard({
  description,
  title,
  value,
  Icon,
}: AdMetricOverviewCardProps) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between pb-1.5">
        <CardTitle className="text-sm font-medium text-gray-500">
          {title}
        </CardTitle>
        <Icon className="h-4 w-4" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-semibold">{value}</div>
        <p className="text-muted-foreground text-xs">{description}</p>
      </CardContent>
    </Card>
  );
}

export type { AdMetricOverviewCardProps };
export { AdMetricOverviewCard };
