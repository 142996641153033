import { AdTimeSeriesMetrics, AdTreeLevel } from '@growthlytic/shared-ad';
import {
  DateRange,
  HttpResponseEnvelopeJson,
  createSerializableDateRange,
} from '@growthlytic/shared-common';
import { env } from '../utils/env';
import { httpClient } from '../utils/http-client';

export type GetAdMetricsPayload = {
  accessToken: string;
  adAccountIdToAdIds: Record<string, string[]>;
  adTreeLevel: AdTreeLevel;
  dateRanges: DateRange<Date>[];
};

export const getAdMetrics = ({
  accessToken,
  adAccountIdToAdIds,
  adTreeLevel,
  dateRanges,
}: GetAdMetricsPayload): Promise<AdTimeSeriesMetrics> =>
  httpClient
    .get<HttpResponseEnvelopeJson<AdTimeSeriesMetrics>>(
      `${env.service.baseUrl}/v1/ad-metrics`,
      {
        searchParams: {
          accessToken,
          adAccountIdToAdIds: JSON.stringify(adAccountIdToAdIds),
          adTreeLevel,
          dateRanges: JSON.stringify(
            dateRanges.map(createSerializableDateRange),
          ),
        },
      },
    )
    .json()
    .then((response) => response.data);
