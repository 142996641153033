import { format } from 'date-fns';
import { DateRange } from './date-range.types';

const FACEBOOK_GRAPH_API_DATETIME_PATTERN = 'yyyy-MM-dd';

export const createDateRange = ({
  since,
  until,
}: DateRange<Date | string>) => ({
  since: new Date(since),
  until: new Date(until),
});

export const createSerializableDateRange = (
  dateRange: DateRange<Date | string>,
): DateRange<string> => ({
  since: new Date(dateRange.since).toISOString(),
  until: new Date(dateRange.until).toISOString(),
});

export const createFacebookGraphApiDateRange = (
  dateRange: DateRange<Date | string>,
): DateRange<string> => ({
  since: format(new Date(dateRange.since), FACEBOOK_GRAPH_API_DATETIME_PATTERN),
  until: format(new Date(dateRange.until), FACEBOOK_GRAPH_API_DATETIME_PATTERN),
});
