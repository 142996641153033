import type { HttpResponseEnvelopeJson } from '@growthlytic/shared-common';
import type {
  CurrentFacebookUser,
  CurrentUser,
} from '@growthlytic/shared-user';
import { env } from '../utils/env';
import { httpClient } from '../utils/http-client';

export type GetCurrentFacebookUserPayload = {
  accessToken: string;
};

export const getCurrentFacebookUser = ({
  accessToken,
}: GetCurrentFacebookUserPayload): Promise<CurrentFacebookUser> =>
  httpClient
    .get<HttpResponseEnvelopeJson<CurrentFacebookUser>>(
      `${env.service.baseUrl}/v1/facebook-users/current`,
      {
        searchParams: { accessToken },
      },
    )
    .json()
    .then((response) => response.data);

export const getCurrentUser = (): Promise<CurrentUser> =>
  httpClient
    .get<HttpResponseEnvelopeJson<CurrentUser>>(
      `${env.service.baseUrl}/v1/users/current`,
    )
    .json()
    .then((response) => response.data);
