import { AdInsight, AdTreeLevel } from '@growthlytic/shared-ad'
import { Interval, createDateRange } from '@growthlytic/shared-common'
import { AdEntity, AdInsightsEntity } from '@growthlytic/web-ads'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  ChartProvider,
  ChartTooltip,
  ChartTooltipContent,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuContentEmptyFallbackMessage,
  DropdownMenuContentLoader,
  DropdownMenuTrigger,
  Separator,
} from '@growthlytic/web-shared-common'
import { createFileRoute } from '@tanstack/react-router'
import { compareAsc, format, subDays } from 'date-fns'
import {
  FileAxis3d,
  FileStack,
  FolderTree,
  Info,
  SlidersHorizontal,
} from 'lucide-react'
import { CartesianGrid, Line, LineChart, XAxis } from 'recharts'
import {
  AdAccountDropdownMenu,
  AdAccountDropdownMenuContent,
  AdAccountDropdownMenuTrigger,
} from '../-components/ad-account-dropdown-menu'
import {
  DateRangeDropdownMenu,
  DateRangeDropdownMenuBody,
  DateRangeDropdownMenuTrigger,
} from '../-components/date-range-dropdown-menu'
import { AdTreeNode, type AdTreeProps } from '../../-components/ad-tree'
import {
  DashboardPage,
  DashboardPageBody,
  DashboardPageHeader,
  DashboardPageHeaderTitle,
} from '../../-components/dashboard-page'
import {
  FacebookApiKeyDialog,
  FacebookApiKeyDialogBody,
  FacebookApiKeyDialogTrigger,
} from '../../-components/facebook-api-key-dialog'
import { useDashboardAdSelectionFilters } from '../../-hooks/use-dashboard-ad-selection-filters'
import { useDashboardComparisonPageEntities } from '../../-hooks/use-dashboard-comparison-page-entities'
import { useDateRangeFilters } from '../../-hooks/use-date-range-filters'
import { useFacebookApiKey } from '../../-hooks/use-facebook-api-key'

const Route = createFileRoute('/_protected/dashboard/_dashboard/comparison/')({
  component: DashboardComparisonPage,
})

function DashboardComparisonPage() {
  const {
    state: { status, value: facebookApiKey },
    handleChange,
    handleSubmit,
  } = useFacebookApiKey()

  const {
    handleIntervalChange,
    handleDateRangeSelect,
    handleDateRangePresetChange,
    selectedInterval,
    selectedDateRange,
    selectedDateRangePreset,
  } = useDateRangeFilters({
    initialInterval: '1d',
    initialDateRange: createDateRange({
      since: subDays(new Date(), 1),
      until: subDays(new Date(), 1),
    }),
    initialDateRangePreset: 'last7Days',
  })

  const {
    selectedAdAccounts,
    selectedAdCampaigns,
    selectedAdSets,
    selectedAds,
    handleSelectedAdAccountChange,
    handleSelectedAdCampaignChange,
    handleSelectedAdSetChange,
    handleSelectedAdChange,
  } = useDashboardAdSelectionFilters()

  const { adAccountEntityQuery, adEntityQuery, adInsightsEntityQuery } =
    useDashboardComparisonPageEntities({
      facebookApiKey,
      selectedAdAccounts,
      selectedAdCampaigns,
      selectedAdSets,
      selectedAds,
      selectedDateRange: selectedDateRange,
      selectedInterval,
    })

  const chartData =
    adEntityQuery.isSuccess && adInsightsEntityQuery.isSuccess
      ? createAdsMetricsChartData({
          adEntity: adEntityQuery.data,
          adInsightsEntity: adInsightsEntityQuery.data,
          filters: {
            selectedAdCampaigns,
            selectedAdSets,
            selectedAds,
            selectedInterval,
          },
        })
      : undefined

  const selectionFilterHandlerByAdTreeLevel = {
    adCampaign: handleSelectedAdCampaignChange,
    adSet: handleSelectedAdSetChange,
    ad: handleSelectedAdChange,
  } as const satisfies Record<
    Exclude<AdTreeLevel, 'root' | 'adAccount'>,
    typeof handleSelectedAdChange
  >

  return (
    <DashboardPage>
      <DashboardPageHeader>
        <DashboardPageHeaderTitle>Metrics</DashboardPageHeaderTitle>

        <div className="flex items-center gap-x-2">
          <FacebookApiKeyDialog>
            <FacebookApiKeyDialogTrigger />
            <FacebookApiKeyDialogBody
              state={{ status, value: facebookApiKey }}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </FacebookApiKeyDialog>
          <DateRangeDropdownMenu
            handleIntervalChange={handleIntervalChange}
            handleDateRangeSelect={handleDateRangeSelect}
            handleDateRangePresetChange={handleDateRangePresetChange}
            selectedInterval={selectedInterval}
            selectedDateRange={selectedDateRange}
            selectedDateRangePreset={selectedDateRangePreset}
          >
            <DateRangeDropdownMenuTrigger />
            <DateRangeDropdownMenuBody />
          </DateRangeDropdownMenu>
        </div>
      </DashboardPageHeader>

      <DashboardPageBody>
        <div className="flex items-center gap-x-4">
          <AdAccountDropdownMenu
            data={adAccountEntityQuery.data}
            handleSelectedAdAccountChange={handleSelectedAdAccountChange}
            selectedAdAccounts={selectedAdAccounts}
            status={adAccountEntityQuery.status}
          >
            <AdAccountDropdownMenuTrigger />
            <AdAccountDropdownMenuContent />
          </AdAccountDropdownMenu>

          <Separator className="h-6" orientation="vertical" />

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <SlidersHorizontal className="mr-1 h-5 w-5" />
                Filters
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-80" align="start">
              {adEntityQuery.isSuccess && adInsightsEntityQuery.isSuccess ? (
                createAdTreeNodes({
                  adEntity: adEntityQuery.data,
                  adInsightsEntity: adInsightsEntityQuery.data,
                  filters: {
                    selectedAdCampaigns,
                    selectedAdSets,
                    selectedAds,
                  },
                }).map((node) => (
                  <AdTreeNode
                    isRoot
                    node={node}
                    onCheckedChange={({ id, type, checked }) =>
                      selectionFilterHandlerByAdTreeLevel[type]({
                        id,
                        selected: checked === true,
                      })
                    }
                  />
                ))
              ) : adEntityQuery.isLoading || adInsightsEntityQuery.isLoading ? (
                <DropdownMenuContentLoader />
              ) : (
                <DropdownMenuContentEmptyFallbackMessage>
                  No items found
                </DropdownMenuContentEmptyFallbackMessage>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="h-4" />

        {adInsightsEntityQuery.isSuccess ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Object.values(chartData?.metrics ?? {}).map(({ title, data }) => (
              <Card key={title}>
                <CardHeader className="flex flex-row items-center space-x-2 space-y-0">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <ChartProvider
                    config={{
                      line1: {
                        color: 'hsl(var(--chart-2))',
                      },
                      line2: {
                        color: 'hsl(var(--chart-1))',
                      },
                      line3: {
                        color: 'hsl(var(--chart-3))',
                      },
                      line4: {
                        color: 'hsl(var(--chart-4))',
                      },
                      line5: {
                        color: 'hsl(var(--chart-5))',
                      },
                    }}
                  >
                    <LineChart
                      accessibilityLayer
                      data={data.map((item) => ({
                        date: item.date,
                        ...item.value,
                      }))}
                      margin={{ top: 20, left: 12, right: 12 }}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis
                        axisLine={false}
                        dataKey="date"
                        fontSize={8}
                        interval="preserveStartEnd"
                        tickLine={false}
                      />
                      <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent indicator="line" />}
                      />
                      {chartData?.selectedItems.map((item, index) => (
                        <Line
                          activeDot={{ r: 6 }}
                          dataKey={item.id}
                          dot={{ fill: `var(--color-line${index + 1})` }}
                          key={item.id}
                          name={item.name}
                          stroke={`var(--color-line${index + 1})`}
                          strokeWidth={2}
                          type="linear"
                        />
                      ))}
                    </LineChart>
                  </ChartProvider>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <Alert>
            <Info className="h-4 w-4" />
            <AlertTitle>Chart unavailable</AlertTitle>
            <AlertDescription>
              Please insert your API key and select the ad account.
            </AlertDescription>
          </Alert>
        )}
      </DashboardPageBody>
    </DashboardPage>
  )
}

type AdMetric = {
  clicks: number
  ctr: number
  costPerResult: number
  impressions: number
  reach: number
  spend: number
  messagingConversationStarted: number
}
type MetricName = keyof AdMetric
type AdMetricDataTypeOf<T extends MetricName> = AdMetric[T]
type AdMetricDataOf<T extends MetricName> = {
  date: string
  value: AdMetricDataTypeOf<T>
}
type AdMetricByDate = Record<string, AdMetric>
type AdMetricsByName = Record<MetricName, AdMetricDataOf<MetricName>[]>

const createAdTreeNodes = ({
  adEntity,
  adInsightsEntity,
  filters: { selectedAdCampaigns, selectedAdSets, selectedAds },
}: {
  adEntity: AdEntity
  adInsightsEntity: AdInsightsEntity
  filters: {
    selectedAdCampaigns: { id: string }[]
    selectedAdSets: { id: string }[]
    selectedAds: { id: string }[]
  }
}) => {
  const nodesByAdCampaignId: Record<string, AdTreeProps['nodes'][number]> = {}
  const nodesByAdSetId: Record<string, AdTreeProps['nodes'][number]> = {}

  adInsightsEntity.allIds.forEach((adId) => {
    const ad = adEntity.byId[adId]
    if (ad === undefined) return

    let adCampaignNode = nodesByAdCampaignId[ad.adCampaign.id]
    let adSetNode = nodesByAdSetId[ad.adSet.id]

    if (adCampaignNode === undefined) {
      adCampaignNode = {
        checked: selectedAdCampaigns.some(
          (item) => item.id === ad.adCampaign.id,
        ),
        children: [],
        disabled: selectedAdSets.length > 0 || selectedAds.length > 0,
        id: ad.adCampaign.id,
        name: ad.adCampaign.name,
        type: 'adCampaign',
        Icon: FolderTree,
      }
      nodesByAdCampaignId[ad.adCampaign.id] = adCampaignNode
    }

    if (adSetNode === undefined) {
      adSetNode = {
        checked:
          selectedAdCampaigns.some((item) => item.id === ad.adCampaign.id) ||
          selectedAdSets.some((item) => item.id === ad.adSet.id),
        children: [],
        disabled: selectedAdCampaigns.length > 0 || selectedAds.length > 0,
        id: ad.adSet.id,
        name: ad.adSet.name,
        type: 'adSet',
        Icon: FileStack,
      }
      nodesByAdSetId[ad.adSet.id] = adSetNode
      adCampaignNode.children?.push(adSetNode)
    }

    const adNode: AdTreeProps['nodes'][number] = {
      checked:
        selectedAdCampaigns.some((item) => item.id === ad.adCampaign.id) ||
        selectedAdSets.some((item) => item.id === ad.adSet.id) ||
        selectedAds.some((item) => item.id === ad.id),
      children: [],
      disabled: selectedAdCampaigns.length > 0 || selectedAdSets.length > 0,
      id: ad.id,
      name: ad.name,
      type: 'ad',
      Icon: FileAxis3d,
    }
    adSetNode.children?.push(adNode)
  })

  return Object.values(nodesByAdCampaignId)
}

const createAdsMetricsChartData = ({
  adEntity,
  adInsightsEntity,
  filters: {
    selectedAdCampaigns,
    selectedAdSets,
    selectedAds,
    selectedInterval,
  },
}: {
  adEntity: AdEntity
  adInsightsEntity: AdInsightsEntity
  filters: {
    selectedAdCampaigns: { id: string }[]
    selectedAdSets: { id: string }[]
    selectedAds: { id: string }[]
    selectedInterval: Interval
  }
}) => {
  const level: AdTreeLevel =
    selectedAdCampaigns.length > 0
      ? 'adCampaign'
      : selectedAdSets.length > 0
        ? 'adSet'
        : 'ad'

  const selectionByLevel = {
    adCampaign: selectedAdCampaigns,
    adSet: selectedAdSets,
    ad: selectedAds,
  } as const satisfies Record<
    Exclude<AdTreeLevel, 'root' | 'adAccount'>,
    { id: string }[]
  >

  const selectedItems = selectionByLevel[level].map(({ id }) => {
    let name
    switch (level) {
      case 'adCampaign':
        name = adEntity.byAdCampaignId[id]?.[0]?.adCampaign.name ?? 'Unnamed'
        break
      case 'adSet':
        name = adEntity.byAdSetId[id]?.[0]?.adSet.name ?? 'Unnamed'
        break
      case 'ad':
        name = adEntity.byId[id]?.name ?? 'Unnamed'
        break
    }

    return { id, name }
  })

  return {
    level,
    selectedItems,
    metrics: {
      ctr: {
        title: 'Click Through Rate (CTR)',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'ctr',
          selectedItems,
          selectedInterval,
        }),
      },
      spend: {
        title: 'Spend',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'spend',
          selectedItems,
          selectedInterval,
        }),
      },
      costPerResult: {
        title: 'Cost Per Result',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'costPerResult',
          selectedItems,
          selectedInterval,
        }),
      },
      impressions: {
        title: 'Impressions',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'impressions',
          selectedItems,
          selectedInterval,
        }),
      },
      reach: {
        title: 'Reach',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'reach',
          selectedItems,
          selectedInterval,
        }),
      },
      messagingConversationStarted: {
        title: 'Messaging Conversation Started',
        data: createAdsMetricChartData({
          adInsightsEntity,
          level,
          metricName: 'messagingConversationStarted',
          selectedItems,
          selectedInterval,
        }),
      },
    },
  } as const
}

const createAdsMetricChartData = ({
  adInsightsEntity,
  level,
  metricName,
  selectedItems,
  selectedInterval,
}: {
  adInsightsEntity: AdInsightsEntity
  level: Exclude<AdTreeLevel, 'root' | 'adAccount'>
  metricName: MetricName
  selectedItems: { id: string }[]
  selectedInterval: Interval
}): {
  date: string
  value: Record<string, number>
}[] => {
  const adsMetricByDate: Record<string, Record<string, number>> = {}

  selectedItems.forEach((x) => {
    const metrics = createAdMetricsByName({
      adInsightsEntity,
      level,
      selectedItemId: x.id,
      selectedInterval,
    })
    metrics[metricName].forEach((y) => {
      const values = adsMetricByDate[y.date] ?? {}
      values[x.id] = y.value
      adsMetricByDate[y.date] = values
    })
  })

  return Object.entries(adsMetricByDate).map(([date, adsMetric]) => ({
    date,
    value: adsMetric,
  }))
}

const createAdMetricsByName = ({
  adInsightsEntity,
  level,
  selectedItemId,
  selectedInterval,
}: {
  adInsightsEntity: AdInsightsEntity
  selectedInterval: Interval
  level: Exclude<AdTreeLevel, 'root' | 'adAccount'>
  selectedItemId: string
}): AdMetricsByName => {
  const metricDataOf = <T extends MetricName>(
    metricName: T,
  ): AdMetricDataOf<T>[] =>
    Object.entries(adMetricByDate)
      .map(([date, adMetric]) => ({
        date,
        value: adMetric[metricName],
      }))
      .sort((a, b) => compareAsc(a.date, b.date))

  const entityIndexByLevel = {
    adCampaign: adInsightsEntity.byAdCampaignId,
    adSet: adInsightsEntity.byAdSetId,
    ad: adInsightsEntity.byId,
  } as const satisfies Record<
    Exclude<AdTreeLevel, 'root' | 'adAccount'>,
    Record<string, AdInsight[]>
  >

  const dateStringFormatPatternByInterval = {
    '1M': 'MM/yyyy',
    '1d': 'dd/MM/yyyy',
  } as const satisfies Record<Interval, string>

  const adInsights = entityIndexByLevel[level][selectedItemId] ?? []
  const adMetricByDate: AdMetricByDate = {}

  adInsights.forEach((adInsight) => {
    const date = format(
      adInsight.startDate,
      dateStringFormatPatternByInterval[selectedInterval],
    )

    if (!adMetricByDate[date]) {
      adMetricByDate[date] = {
        clicks: 0,
        costPerResult: -1,
        ctr: 0,
        impressions: 0,
        messagingConversationStarted: 0,
        reach: 0,
        spend: 0,
      }
    }

    adMetricByDate[date].clicks += adInsight.clicks
    adMetricByDate[date].spend += adInsight.spend
    adMetricByDate[date].reach += adInsight.reach
    adMetricByDate[date].impressions += adInsight.impressions
    adMetricByDate[date].messagingConversationStarted +=
      adInsight.messagingConversationStarted
  })

  Object.keys(adMetricByDate).forEach((date) => {
    const metric = adMetricByDate[date]
    if (metric !== undefined) {
      metric.ctr =
        metric.impressions > 0 ? (metric.clicks / metric.impressions) * 100 : 0
      metric.costPerResult =
        metric.messagingConversationStarted > 0
          ? metric.spend / metric.messagingConversationStarted
          : 0
    }
  })

  return {
    clicks: metricDataOf('clicks'),
    costPerResult: metricDataOf('costPerResult'),
    ctr: metricDataOf('ctr'),
    impressions: metricDataOf('impressions'),
    messagingConversationStarted: metricDataOf('messagingConversationStarted'),
    reach: metricDataOf('reach'),
    spend: metricDataOf('spend'),
  } as const satisfies AdMetricsByName
}

export { Route }
