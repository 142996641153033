const mode = import.meta.env.MODE;

if (mode !== 'development' && mode !== 'production') {
  throw new Error('Invalid environment mode');
}

export const isDev = () => mode === 'development';
export const isProd = () => mode === 'production';

export const env = (
  {
    development: {
      baseUrl: 'http://localhost:4200',
      firebase: {
        apiKey: 'AIzaSyCIp6i1IAPrqH1V9cr9GVEruhirwDCzyHI',
        authDomain: 'growthlytic-io.firebaseapp.com',
        projectId: 'growthlytic-io',
        storageBucket: 'growthlytic-io.firebasestorage.app',
        messagingSenderId: '20138727084',
        appId: '1:20138727084:web:ea78c521cd6185bf817109',
        measurementId: 'G-ZZM9DZMMKE',
      },
      service: {
        baseUrl: 'http://localhost:8000/api',
      },
    },
    production: {
      baseUrl: 'https://growthlytic.io',
      firebase: {
        apiKey: 'AIzaSyCIp6i1IAPrqH1V9cr9GVEruhirwDCzyHI',
        authDomain: 'growthlytic-io.firebaseapp.com',
        projectId: 'growthlytic-io',
        storageBucket: 'growthlytic-io.firebasestorage.app',
        messagingSenderId: '20138727084',
        appId: '1:20138727084:web:ea78c521cd6185bf817109',
        measurementId: 'G-ZZM9DZMMKE',
      },
      service: {
        baseUrl: 'https://service.growthlytic.io/api',
      },
    },
  } as const
)[mode];
