import { Fallback, Image, Root } from '@radix-ui/react-avatar';
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type FC,
  forwardRef,
} from 'react';
import { cn } from '../utils/class-name';

const Avatar: FC<ComponentPropsWithoutRef<typeof Root>> = forwardRef<
  ElementRef<typeof Root>,
  ComponentPropsWithoutRef<typeof Root>
>(({ className, ...props }, ref) => (
  <Root
    ref={ref}
    className={cn(
      'relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full',
      className,
    )}
    {...props}
  />
));
Avatar.displayName = Root.displayName;

const AvatarImage: FC<ComponentPropsWithoutRef<typeof Image>> = forwardRef<
  ElementRef<typeof Image>,
  ComponentPropsWithoutRef<typeof Image>
>(({ className, ...props }, ref) => (
  <Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
));
AvatarImage.displayName = Image.displayName;

const AvatarFallback: FC<ComponentPropsWithoutRef<typeof Fallback>> =
  forwardRef<
    ElementRef<typeof Fallback>,
    ComponentPropsWithoutRef<typeof Fallback>
  >(({ className, ...props }, ref) => (
    <Fallback
      ref={ref}
      className={cn(
        'bg-muted flex h-full w-full items-center justify-center rounded-full',
        className,
      )}
      {...props}
    />
  ));
AvatarFallback.displayName = Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
