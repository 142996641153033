import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from '@growthlytic/web-shared-common';
import { KeyRound } from 'lucide-react';
import type { PropsWithChildren } from 'react';
import type { useFacebookApiKey } from '../-hooks/use-facebook-api-key';

function FacebookApiKeyDialog({ children }: PropsWithChildren) {
  return <Dialog>{children}</Dialog>;
}

function FacebookApiKeyDialogTrigger() {
  return (
    <DialogTrigger asChild>
      <Button>
        <KeyRound />
        Facebook API Key
      </Button>
    </DialogTrigger>
  );
}

function FacebookApiKeyDialogBody({
  state,
  handleChange,
  handleSubmit,
}: {
  state: ReturnType<typeof useFacebookApiKey>['state'];
  handleChange: (value: string) => void;
  handleSubmit: () => void;
}) {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Facebook API Key</DialogTitle>
        <DialogDescription>
          Update your Facebook API Key to access AD data. Click save when you're
          done.
        </DialogDescription>
      </DialogHeader>
      <div className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="facebookApiKey" className="text-right">
          API Key
        </Label>
        <Input
          className="col-span-3"
          id="facebookApiKey"
          onChange={(event) => handleChange(event.target.value)}
          value={state.status === 'pending' ? state.value : '****************'}
        />
      </div>
      <DialogFooter>
        <Button onClick={handleSubmit}>Save changes</Button>
      </DialogFooter>
    </DialogContent>
  );
}

export {
  FacebookApiKeyDialog,
  FacebookApiKeyDialogTrigger,
  FacebookApiKeyDialogBody,
};
