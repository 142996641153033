import { LoadingPage } from '@growthlytic/web-shared-common';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuthContext } from '../hooks/use-auth-context';

export function AuthVerifyEmailLinkPage() {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    authContext.handleEmailLinkSignInOrSignUp({
      emailLink: window.location.href,
    });
  }, []); // eslint-disable-line -- this useEffect only have to run on first mount

  useEffect(() => {
    if (authContext.isAuthenticated)
      navigate({
        from: '/auth/verify-email-link',
        to: '/dashboard/overview',
      });
  }, [authContext.isAuthenticated, navigate]);

  return <LoadingPage text="Verifying your email link..." />;
}
