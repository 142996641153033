import { type ComponentProps, type FC, forwardRef } from 'react';
import type { LegendProps } from 'recharts';
import { cn } from '../../utils/class-name';
import { useChartContext } from './chart-provider';
import { getPayloadConfigFromPayload } from './chart-utils';

type ChartLegendContentProps = ComponentProps<'div'> &
  Pick<LegendProps, 'payload' | 'verticalAlign'> & {
    hideIcon?: boolean;
    nameKey?: string;
  };

const ChartLegendContent: FC<ChartLegendContentProps> = forwardRef<
  HTMLDivElement,
  ChartLegendContentProps
>(
  (
    { className, hideIcon = false, payload, verticalAlign = 'bottom', nameKey },
    ref,
  ) => {
    const { config } = useChartContext();

    if (!payload?.length) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={cn(
          'flex items-center justify-center gap-4',
          verticalAlign === 'top' ? 'pb-3' : 'pt-3',
          className,
        )}
      >
        {payload.map((item) => {
          const key = nameKey ?? item.dataKey ?? 'value';
          const itemConfig = getPayloadConfigFromPayload(
            config,
            item,
            key.toString(),
          );

          return (
            <div
              key={item.value}
              className={cn(
                '[&>svg]:text-muted-foreground flex items-center gap-1.5 [&>svg]:h-3 [&>svg]:w-3',
              )}
            >
              {itemConfig?.icon && !hideIcon ? (
                <itemConfig.icon />
              ) : (
                <div
                  className="h-2 w-2 shrink-0 rounded-[2px]"
                  style={{
                    backgroundColor: item.color,
                  }}
                />
              )}
              {itemConfig?.label}
            </div>
          );
        })}
      </div>
    );
  },
);
ChartLegendContent.displayName = 'ChartLegendContent';

export { ChartLegendContent };
