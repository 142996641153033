import { z } from 'zod';

export const intervals = ['1M', '1d'] as const;

export type Interval = (typeof intervals)[number];

export const dateRangeSchema = z.object({
  since: z.string().datetime(),
  until: z.string().datetime(),
});

export type DateRange<T extends Date | string = Date> = {
  since: T;
  until: T;
};
