import { AsyncState } from '@growthlytic/shared-common';
import { CurrentUser } from '@growthlytic/shared-user';
import { createContext } from 'react';

export type AuthContextValue = {
  isEmailLinkSent: boolean;
  status: AsyncState['status'];
  handleEmailLinkSend: (input: { email: string }) => Promise<void>;
  handleEmailLinkSignInOrSignUp: (input: {
    emailLink: string;
  }) => Promise<void>;
  handleSignOut: () => Promise<void>;
} & (
  | { user: null; isAuthenticated: false }
  | { user: CurrentUser; isAuthenticated: true }
);

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);
