import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import { type CustomComponents, DayPicker } from 'react-day-picker';
import { cn } from '../utils/class-name';
import { buttonVariants } from './button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={className}
      classNames={{
        months:
          'relative flex flex-col gap-y-4 sm:flex-row sm:gap-x-4 sm:gap-y-0 [&>*:nth-child(2)]:-mt-4 sm:[&>*:nth-child(2)]:-mt-0 sm:[&>*:nth-child(2)]:-ml-4',
        month: '',
        month_caption: 'flex justify-center pt-1 relative items-center',
        month_grid: 'w-full border-collapse space-y-1 mt-4',
        caption_label: 'text-sm font-medium',
        nav: '',
        button_previous: cn(
          buttonVariants({ variant: 'outline' }),
          'absolute left-1 z-10 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        button_next: cn(
          buttonVariants({ variant: 'outline' }),
          'absolute right-1 z-10 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        weekdays: 'flex',
        weekday:
          'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
        week: 'flex w-full mt-2',
        day: cn(
          '[&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected].day-range-end)]:rounded-r-md',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md',
        ),
        day_button: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100',
        ),
        range_start: 'day-range-start',
        range_end: 'day-range-end',
        selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        today: 'bg-accent text-accent-foreground',
        outside:
          'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        disabled: 'text-muted-foreground opacity-50',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        ...classNames,
      }}
      components={
        {
          Chevron: (props) =>
            props.orientation === 'left' ? (
              <ChevronLeft {...props} />
            ) : (
              <ChevronRight {...props} />
            ),
        } as Partial<CustomComponents>
      }
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
