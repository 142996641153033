import { type Dispatch, type SetStateAction, useState } from 'react';

const useDashboardAdSelectionFilters = () => {
  const [selectedAdAccounts, setSelectedAdAccounts] = useState<
    { id: string }[]
  >([]);
  const [selectedAdCampaigns, setSelectedAdCampaigns] = useState<
    { id: string }[]
  >([]);
  const [selectedAdSets, setSelectedAdSets] = useState<{ id: string }[]>([]);
  const [selectedAds, setSelectedAds] = useState<{ id: string }[]>([]);

  const selectionFilterSetterBySelectionFilterName = {
    selectedAdAccounts: setSelectedAdAccounts,
    selectedAdCampaigns: setSelectedAdCampaigns,
    selectedAdSets: setSelectedAdSets,
    selectedAds: setSelectedAds,
  } as const satisfies Record<
    string,
    Dispatch<SetStateAction<{ id: string }[]>>
  >;

  const createSelectionFilterChangeHandler =
    (adUiFilter: keyof typeof selectionFilterSetterBySelectionFilterName) =>
    ({ id, selected }: { id: string; selected: boolean }) => {
      const set = selectionFilterSetterBySelectionFilterName[adUiFilter];
      selected
        ? set((prev) => [...prev, { id }])
        : set((prev) => prev.filter((item) => item.id !== id));
    };

  return {
    handleSelectedAdAccountChange:
      createSelectionFilterChangeHandler('selectedAdAccounts'),
    handleSelectedAdCampaignChange: createSelectionFilterChangeHandler(
      'selectedAdCampaigns',
    ),
    handleSelectedAdSetChange:
      createSelectionFilterChangeHandler('selectedAdSets'),
    handleSelectedAdChange: createSelectionFilterChangeHandler('selectedAds'),

    selectedAdAccounts,
    selectedAdCampaigns,
    selectedAdSets,
    selectedAds,
  } as const;
};

export { useDashboardAdSelectionFilters };
