import type { AdInsight } from '@growthlytic/shared-ad';
import {
  type GetAdInsightsPayload,
  getAdInsights,
} from '@growthlytic/web-shared-common';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

export type AdInsightsEntity = {
  allIds: Set<AdInsight['adId']>;
  allAdCampaignIds: Set<AdInsight['adCampaignId']>;
  allAdSetIds: Set<AdInsight['adSetId']>;
  byId: Record<string, AdInsight[]>;
  byAdCampaignId: Record<string, AdInsight[]>;
  byAdSetId: Record<string, AdInsight[]>;
};

type UseGetAdInsightsEntityQueryOptions = UseQueryOptions<
  Awaited<AdInsightsEntity>,
  unknown,
  Awaited<AdInsightsEntity>,
  string[]
>;

export const useGetAdInsightsEntityQuery = (
  payload: GetAdInsightsPayload,
  options?: Omit<UseGetAdInsightsEntityQueryOptions, 'queryFn' | 'queryKey'>,
) =>
  useQuery({
    queryKey: [
      'adAccountIdToAdIds',
      JSON.stringify(payload.adAccountIdToAdIds),
      'dateRange',
      JSON.stringify(payload.dateRange),
      'intervals',
      payload.interval,
    ],
    queryFn: async () => createAdInsightsEntity(await getAdInsights(payload)),
    ...options,
  });

const createAdInsightsEntity = (data: AdInsight[] | undefined) => {
  const entity: AdInsightsEntity = {
    allIds: new Set(),
    allAdCampaignIds: new Set(),
    allAdSetIds: new Set(),
    byId: {},
    byAdCampaignId: {},
    byAdSetId: {},
  };

  if (data === undefined) return entity;

  data.forEach((item) => {
    entity.allIds.add(item.adId);
    entity.allAdCampaignIds.add(item.adCampaignId);
    entity.allAdSetIds.add(item.adSetId);

    if (entity.byId[item.adId] === undefined) entity.byId[item.adId] = [];
    entity.byId[item.adId]?.push(item);

    if (entity.byAdCampaignId[item.adCampaignId] === undefined)
      entity.byAdCampaignId[item.adCampaignId] = [];
    entity.byAdCampaignId[item.adCampaignId]?.push(item);

    if (entity.byAdSetId[item.adSetId] === undefined)
      entity.byAdSetId[item.adSetId] = [];
    entity.byAdSetId[item.adSetId]?.push(item);
  });

  return entity;
};
