import type { DateRange } from '@growthlytic/shared-common';
import type { AdTreeLevel } from '../ad-tree/ad-tree.types';

export type MoneyMetricUnit = 'money';
export type CountMetricUnit = 'count';
export type PercentageMetricUnit = 'percentage';
export type NonMoneyMetricUnit = CountMetricUnit | PercentageMetricUnit;
export type MetricUnit = MoneyMetricUnit | NonMoneyMetricUnit;

export type Money = {
  amount: number;
  currency: string;
};

export type TimeSeriesMetricDataPointValueOf<T extends MetricUnit> =
  T extends MoneyMetricUnit
    ? Money
    : T extends PercentageMetricUnit | CountMetricUnit
      ? number
      : never;

export type TimeSeriesMetricDataPointValue = Money | number;

type BaseTimeSeriesMetricDataPoint = {
  dateRange: DateRange<string>;
};

export type MoneyTimeSeriesMetricDataPoint = BaseTimeSeriesMetricDataPoint & {
  unit: MoneyMetricUnit;
  value: Money;
};
export type PercentageTimeSeriesMetricDataPoint =
  BaseTimeSeriesMetricDataPoint & {
    unit: PercentageMetricUnit;
    value: number;
  };
export type CountTimeSeriesMetricDataPoint = BaseTimeSeriesMetricDataPoint & {
  unit: CountMetricUnit;
  value: number;
};

export type TimeSeriesMetricDataPointOfUnit<T extends MetricUnit> =
  T extends MoneyMetricUnit
    ? MoneyTimeSeriesMetricDataPoint
    : T extends PercentageMetricUnit
      ? PercentageTimeSeriesMetricDataPoint
      : T extends CountMetricUnit
        ? CountTimeSeriesMetricDataPoint
        : never;

export type TimeSeriesMetricDataPoint =
  | MoneyTimeSeriesMetricDataPoint
  | PercentageTimeSeriesMetricDataPoint
  | CountTimeSeriesMetricDataPoint;

export type MoneyTimeSeriesMetric<T extends MoneyTimeSeriesMetricDataPoint> = {
  name: string;
  unit: MoneyMetricUnit;
  dataPoints: T[];
};
export type PercentageTimeSeriesMetric<
  T extends PercentageTimeSeriesMetricDataPoint,
> = {
  name: string;
  unit: PercentageMetricUnit;
  dataPoints: T[];
};
export type CountTimeSeriesMetric<T extends CountTimeSeriesMetricDataPoint> = {
  name: string;
  unit: CountMetricUnit;
  dataPoints: T[];
};

export type WithAdAttributes<T> = T & {
  attributes: AdTimeSeriesMetricDataPointAttributes;
};

export type AdTimeSeriesMetricDataPointAttributes = {
  adTreeLevel: AdTreeLevel;
  adAccountIds: string[];
  adCampaignIds: string[];
  adSetIds: string[];
  adIds: string[];
};

export type MoneyAdTimeSeriesMetricDataPoint =
  WithAdAttributes<MoneyTimeSeriesMetricDataPoint>;
export type PercentageAdTimeSeriesMetricDataPoint =
  WithAdAttributes<PercentageTimeSeriesMetricDataPoint>;
export type CountAdTimeSeriesMetricDataPoint =
  WithAdAttributes<CountTimeSeriesMetricDataPoint>;

export type AdTimeSeriesMetricDataPoint =
  | MoneyAdTimeSeriesMetricDataPoint
  | PercentageAdTimeSeriesMetricDataPoint
  | CountAdTimeSeriesMetricDataPoint;

export type MoneyAdTimeSeriesMetric =
  MoneyTimeSeriesMetric<MoneyAdTimeSeriesMetricDataPoint>;
export type PercentageAdTimeSeriesMetric =
  PercentageTimeSeriesMetric<PercentageAdTimeSeriesMetricDataPoint>;
export type CountAdTimeSeriesMetric =
  CountTimeSeriesMetric<CountAdTimeSeriesMetricDataPoint>;

export type AdTimeSeriesMetric =
  | MoneyAdTimeSeriesMetric
  | PercentageAdTimeSeriesMetric
  | CountAdTimeSeriesMetric;

export type AdTimeSeriesMetrics = {
  clicks: CountAdTimeSeriesMetric;
  costPerResult: MoneyAdTimeSeriesMetric;
  ctr: PercentageAdTimeSeriesMetric;
  impressions: CountAdTimeSeriesMetric;
  messagingConversationStarted: CountAdTimeSeriesMetric;
  reach: CountAdTimeSeriesMetric;
  spend: MoneyAdTimeSeriesMetric;
};
export type AdMetricName = keyof AdTimeSeriesMetrics;

export const rawAdMetricNames = [
  'clicks',
  'impressions',
  'messagingConversationStarted',
  'reach',
  'spend',
] as const satisfies AdMetricName[];
export const computedAdMetricNames = [
  'costPerResult',
  'ctr',
] as const satisfies AdMetricName[];

export const adMetricNames = [
  ...rawAdMetricNames,
  ...computedAdMetricNames,
] as const;

export type RawAdMetricName = (typeof rawAdMetricNames)[number];
export type ComputedAdMetricName = (typeof computedAdMetricNames)[number];

export const rawMetricNameToUnit = {
  clicks: 'count',
  impressions: 'count',
  messagingConversationStarted: 'count',
  reach: 'count',
  spend: 'money',
} as const satisfies Record<RawAdMetricName, MetricUnit>;

export const computedMetricNameToUnit = {
  costPerResult: 'money',
  ctr: 'percentage',
} as const satisfies Record<ComputedAdMetricName, MetricUnit>;
