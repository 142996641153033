import { AdAccountEntity } from '@growthlytic/web-ads';
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentEmptyFallbackMessage,
  DropdownMenuContentLoader,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@growthlytic/web-shared-common';
import { UsersIcon } from 'lucide-react';
import { PropsWithChildren, createContext, useContext } from 'react';

type AsyncData<T> = {
  status: 'success' | 'pending' | 'error';
  data?: T;
};

type AdAccountDropdownMenuContextValue = AsyncData<AdAccountEntity> & {
  selectedAdAccounts: { id: string }[];
  handleSelectedAdAccountChange: (input: {
    id: string;
    selected: boolean;
  }) => void;
};

const AdAccountDropdownMenuContext = createContext<
  AdAccountDropdownMenuContextValue | undefined
>(undefined);

function AdAccountDropdownMenu({
  children,
  data,
  handleSelectedAdAccountChange,
  selectedAdAccounts,
  status,
}: PropsWithChildren<AdAccountDropdownMenuContextValue>) {
  return (
    <DropdownMenu>
      <AdAccountDropdownMenuContext.Provider
        value={
          {
            data,
            handleSelectedAdAccountChange,
            selectedAdAccounts,
            status,
          } as AdAccountDropdownMenuContextValue
        }
      >
        {children}
      </AdAccountDropdownMenuContext.Provider>
    </DropdownMenu>
  );
}

function AdAccountDropdownMenuTrigger() {
  return (
    <DropdownMenuTrigger asChild>
      <Button variant="outline">
        <UsersIcon className="mr-1 h-5 w-5" />
        Ad Accounts
      </Button>
    </DropdownMenuTrigger>
  );
}

function AdAccountDropdownMenuContent() {
  const context = useAdAccountDropdownMenuContext();

  return (
    <DropdownMenuContent className="w-48" align="start">
      <DropdownMenuGroup>
        {context.status === 'success' ? (
          [...(context.data?.allIds ?? [])].map((adAccountId) => (
            <DropdownMenuCheckboxItem
              key={adAccountId}
              checked={
                context.selectedAdAccounts.find(
                  (item) => item.id === adAccountId,
                ) !== undefined
              }
              onCheckedChange={(checked) =>
                context.handleSelectedAdAccountChange({
                  id: adAccountId,
                  selected: checked,
                })
              }
            >
              {context.data?.byId[adAccountId]?.name ?? '-'}
            </DropdownMenuCheckboxItem>
          ))
        ) : context.status === 'pending' ? (
          <DropdownMenuContentLoader />
        ) : (
          <DropdownMenuContentEmptyFallbackMessage>
            No items found
          </DropdownMenuContentEmptyFallbackMessage>
        )}
      </DropdownMenuGroup>
    </DropdownMenuContent>
  );
}

const useAdAccountDropdownMenuContext = () => {
  const context = useContext(AdAccountDropdownMenuContext);
  if (context === undefined)
    throw new Error(
      'useAdAccountDropdownMenuContext must be used within AdAccountDropdownMenuContext.Provider',
    );
  return context;
};

export {
  AdAccountDropdownMenu,
  AdAccountDropdownMenuTrigger,
  AdAccountDropdownMenuContent,
};
