/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ProtectedImport } from './routes/_protected'
import { Route as IndexImport } from './routes/index'
import { Route as AuthVerifyEmailLinkImport } from './routes/auth/verify-email-link'
import { Route as AuthSignUpImport } from './routes/auth/sign-up'
import { Route as AuthSignOutImport } from './routes/auth/sign-out'
import { Route as AuthSignInImport } from './routes/auth/sign-in'
import { Route as ProtectedDashboardDashboardImport } from './routes/_protected/dashboard/_dashboard'
import { Route as ProtectedDashboardDashboardIndexImport } from './routes/_protected/dashboard/_dashboard.index'
import { Route as ProtectedDashboardDashboardAdsImport } from './routes/_protected/dashboard/_dashboard.ads'
import { Route as ProtectedDashboardDashboardOverviewIndexImport } from './routes/_protected/dashboard/_dashboard/overview/index'
import { Route as ProtectedDashboardDashboardComparisonIndexImport } from './routes/_protected/dashboard/_dashboard/comparison/index'

// Create Virtual Routes

const ProtectedDashboardImport = createFileRoute('/_protected/dashboard')()

// Create/Update Routes

const ProtectedRoute = ProtectedImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ProtectedDashboardRoute = ProtectedDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => ProtectedRoute,
} as any)

const AuthVerifyEmailLinkRoute = AuthVerifyEmailLinkImport.update({
  id: '/auth/verify-email-link',
  path: '/auth/verify-email-link',
  getParentRoute: () => rootRoute,
} as any)

const AuthSignUpRoute = AuthSignUpImport.update({
  id: '/auth/sign-up',
  path: '/auth/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const AuthSignOutRoute = AuthSignOutImport.update({
  id: '/auth/sign-out',
  path: '/auth/sign-out',
  getParentRoute: () => rootRoute,
} as any)

const AuthSignInRoute = AuthSignInImport.update({
  id: '/auth/sign-in',
  path: '/auth/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const ProtectedDashboardDashboardRoute =
  ProtectedDashboardDashboardImport.update({
    id: '/_dashboard',
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardDashboardIndexRoute =
  ProtectedDashboardDashboardIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ProtectedDashboardDashboardRoute,
  } as any)

const ProtectedDashboardDashboardAdsRoute =
  ProtectedDashboardDashboardAdsImport.update({
    id: '/ads',
    path: '/ads',
    getParentRoute: () => ProtectedDashboardDashboardRoute,
  } as any)

const ProtectedDashboardDashboardOverviewIndexRoute =
  ProtectedDashboardDashboardOverviewIndexImport.update({
    id: '/overview/',
    path: '/overview/',
    getParentRoute: () => ProtectedDashboardDashboardRoute,
  } as any)

const ProtectedDashboardDashboardComparisonIndexRoute =
  ProtectedDashboardDashboardComparisonIndexImport.update({
    id: '/comparison/',
    path: '/comparison/',
    getParentRoute: () => ProtectedDashboardDashboardRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    '/auth/sign-in': {
      id: '/auth/sign-in'
      path: '/auth/sign-in'
      fullPath: '/auth/sign-in'
      preLoaderRoute: typeof AuthSignInImport
      parentRoute: typeof rootRoute
    }
    '/auth/sign-out': {
      id: '/auth/sign-out'
      path: '/auth/sign-out'
      fullPath: '/auth/sign-out'
      preLoaderRoute: typeof AuthSignOutImport
      parentRoute: typeof rootRoute
    }
    '/auth/sign-up': {
      id: '/auth/sign-up'
      path: '/auth/sign-up'
      fullPath: '/auth/sign-up'
      preLoaderRoute: typeof AuthSignUpImport
      parentRoute: typeof rootRoute
    }
    '/auth/verify-email-link': {
      id: '/auth/verify-email-link'
      path: '/auth/verify-email-link'
      fullPath: '/auth/verify-email-link'
      preLoaderRoute: typeof AuthVerifyEmailLinkImport
      parentRoute: typeof rootRoute
    }
    '/_protected/dashboard': {
      id: '/_protected/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof ProtectedDashboardImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/dashboard/_dashboard': {
      id: '/_protected/dashboard/_dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof ProtectedDashboardDashboardImport
      parentRoute: typeof ProtectedDashboardRoute
    }
    '/_protected/dashboard/_dashboard/ads': {
      id: '/_protected/dashboard/_dashboard/ads'
      path: '/ads'
      fullPath: '/dashboard/ads'
      preLoaderRoute: typeof ProtectedDashboardDashboardAdsImport
      parentRoute: typeof ProtectedDashboardDashboardImport
    }
    '/_protected/dashboard/_dashboard/': {
      id: '/_protected/dashboard/_dashboard/'
      path: '/'
      fullPath: '/dashboard/'
      preLoaderRoute: typeof ProtectedDashboardDashboardIndexImport
      parentRoute: typeof ProtectedDashboardDashboardImport
    }
    '/_protected/dashboard/_dashboard/comparison/': {
      id: '/_protected/dashboard/_dashboard/comparison/'
      path: '/comparison'
      fullPath: '/dashboard/comparison'
      preLoaderRoute: typeof ProtectedDashboardDashboardComparisonIndexImport
      parentRoute: typeof ProtectedDashboardDashboardImport
    }
    '/_protected/dashboard/_dashboard/overview/': {
      id: '/_protected/dashboard/_dashboard/overview/'
      path: '/overview'
      fullPath: '/dashboard/overview'
      preLoaderRoute: typeof ProtectedDashboardDashboardOverviewIndexImport
      parentRoute: typeof ProtectedDashboardDashboardImport
    }
  }
}

// Create and export the route tree

interface ProtectedDashboardDashboardRouteChildren {
  ProtectedDashboardDashboardAdsRoute: typeof ProtectedDashboardDashboardAdsRoute
  ProtectedDashboardDashboardIndexRoute: typeof ProtectedDashboardDashboardIndexRoute
  ProtectedDashboardDashboardComparisonIndexRoute: typeof ProtectedDashboardDashboardComparisonIndexRoute
  ProtectedDashboardDashboardOverviewIndexRoute: typeof ProtectedDashboardDashboardOverviewIndexRoute
}

const ProtectedDashboardDashboardRouteChildren: ProtectedDashboardDashboardRouteChildren =
  {
    ProtectedDashboardDashboardAdsRoute: ProtectedDashboardDashboardAdsRoute,
    ProtectedDashboardDashboardIndexRoute:
      ProtectedDashboardDashboardIndexRoute,
    ProtectedDashboardDashboardComparisonIndexRoute:
      ProtectedDashboardDashboardComparisonIndexRoute,
    ProtectedDashboardDashboardOverviewIndexRoute:
      ProtectedDashboardDashboardOverviewIndexRoute,
  }

const ProtectedDashboardDashboardRouteWithChildren =
  ProtectedDashboardDashboardRoute._addFileChildren(
    ProtectedDashboardDashboardRouteChildren,
  )

interface ProtectedDashboardRouteChildren {
  ProtectedDashboardDashboardRoute: typeof ProtectedDashboardDashboardRouteWithChildren
}

const ProtectedDashboardRouteChildren: ProtectedDashboardRouteChildren = {
  ProtectedDashboardDashboardRoute:
    ProtectedDashboardDashboardRouteWithChildren,
}

const ProtectedDashboardRouteWithChildren =
  ProtectedDashboardRoute._addFileChildren(ProtectedDashboardRouteChildren)

interface ProtectedRouteChildren {
  ProtectedDashboardRoute: typeof ProtectedDashboardRouteWithChildren
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedDashboardRoute: ProtectedDashboardRouteWithChildren,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof ProtectedRouteWithChildren
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth/sign-out': typeof AuthSignOutRoute
  '/auth/sign-up': typeof AuthSignUpRoute
  '/auth/verify-email-link': typeof AuthVerifyEmailLinkRoute
  '/dashboard': typeof ProtectedDashboardDashboardRouteWithChildren
  '/dashboard/ads': typeof ProtectedDashboardDashboardAdsRoute
  '/dashboard/': typeof ProtectedDashboardDashboardIndexRoute
  '/dashboard/comparison': typeof ProtectedDashboardDashboardComparisonIndexRoute
  '/dashboard/overview': typeof ProtectedDashboardDashboardOverviewIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof ProtectedRouteWithChildren
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth/sign-out': typeof AuthSignOutRoute
  '/auth/sign-up': typeof AuthSignUpRoute
  '/auth/verify-email-link': typeof AuthVerifyEmailLinkRoute
  '/dashboard': typeof ProtectedDashboardDashboardIndexRoute
  '/dashboard/ads': typeof ProtectedDashboardDashboardAdsRoute
  '/dashboard/comparison': typeof ProtectedDashboardDashboardComparisonIndexRoute
  '/dashboard/overview': typeof ProtectedDashboardDashboardOverviewIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_protected': typeof ProtectedRouteWithChildren
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth/sign-out': typeof AuthSignOutRoute
  '/auth/sign-up': typeof AuthSignUpRoute
  '/auth/verify-email-link': typeof AuthVerifyEmailLinkRoute
  '/_protected/dashboard': typeof ProtectedDashboardRouteWithChildren
  '/_protected/dashboard/_dashboard': typeof ProtectedDashboardDashboardRouteWithChildren
  '/_protected/dashboard/_dashboard/ads': typeof ProtectedDashboardDashboardAdsRoute
  '/_protected/dashboard/_dashboard/': typeof ProtectedDashboardDashboardIndexRoute
  '/_protected/dashboard/_dashboard/comparison/': typeof ProtectedDashboardDashboardComparisonIndexRoute
  '/_protected/dashboard/_dashboard/overview/': typeof ProtectedDashboardDashboardOverviewIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/auth/sign-in'
    | '/auth/sign-out'
    | '/auth/sign-up'
    | '/auth/verify-email-link'
    | '/dashboard'
    | '/dashboard/ads'
    | '/dashboard/'
    | '/dashboard/comparison'
    | '/dashboard/overview'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/auth/sign-in'
    | '/auth/sign-out'
    | '/auth/sign-up'
    | '/auth/verify-email-link'
    | '/dashboard'
    | '/dashboard/ads'
    | '/dashboard/comparison'
    | '/dashboard/overview'
  id:
    | '__root__'
    | '/'
    | '/_protected'
    | '/auth/sign-in'
    | '/auth/sign-out'
    | '/auth/sign-up'
    | '/auth/verify-email-link'
    | '/_protected/dashboard'
    | '/_protected/dashboard/_dashboard'
    | '/_protected/dashboard/_dashboard/ads'
    | '/_protected/dashboard/_dashboard/'
    | '/_protected/dashboard/_dashboard/comparison/'
    | '/_protected/dashboard/_dashboard/overview/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ProtectedRoute: typeof ProtectedRouteWithChildren
  AuthSignInRoute: typeof AuthSignInRoute
  AuthSignOutRoute: typeof AuthSignOutRoute
  AuthSignUpRoute: typeof AuthSignUpRoute
  AuthVerifyEmailLinkRoute: typeof AuthVerifyEmailLinkRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ProtectedRoute: ProtectedRouteWithChildren,
  AuthSignInRoute: AuthSignInRoute,
  AuthSignOutRoute: AuthSignOutRoute,
  AuthSignUpRoute: AuthSignUpRoute,
  AuthVerifyEmailLinkRoute: AuthVerifyEmailLinkRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_protected",
        "/auth/sign-in",
        "/auth/sign-out",
        "/auth/sign-up",
        "/auth/verify-email-link"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/dashboard"
      ]
    },
    "/auth/sign-in": {
      "filePath": "auth/sign-in.tsx"
    },
    "/auth/sign-out": {
      "filePath": "auth/sign-out.tsx"
    },
    "/auth/sign-up": {
      "filePath": "auth/sign-up.tsx"
    },
    "/auth/verify-email-link": {
      "filePath": "auth/verify-email-link.tsx"
    },
    "/_protected/dashboard": {
      "filePath": "_protected/dashboard",
      "parent": "/_protected",
      "children": [
        "/_protected/dashboard/_dashboard"
      ]
    },
    "/_protected/dashboard/_dashboard": {
      "filePath": "_protected/dashboard/_dashboard.tsx",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/_dashboard/ads",
        "/_protected/dashboard/_dashboard/",
        "/_protected/dashboard/_dashboard/comparison/",
        "/_protected/dashboard/_dashboard/overview/"
      ]
    },
    "/_protected/dashboard/_dashboard/ads": {
      "filePath": "_protected/dashboard/_dashboard.ads.tsx",
      "parent": "/_protected/dashboard/_dashboard"
    },
    "/_protected/dashboard/_dashboard/": {
      "filePath": "_protected/dashboard/_dashboard.index.tsx",
      "parent": "/_protected/dashboard/_dashboard"
    },
    "/_protected/dashboard/_dashboard/comparison/": {
      "filePath": "_protected/dashboard/_dashboard/comparison/index.tsx",
      "parent": "/_protected/dashboard/_dashboard"
    },
    "/_protected/dashboard/_dashboard/overview/": {
      "filePath": "_protected/dashboard/_dashboard/overview/index.tsx",
      "parent": "/_protected/dashboard/_dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
