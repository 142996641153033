import type { AdTreeLevel } from '@growthlytic/shared-ad';
import {
  Button,
  Checkbox,
  type CheckboxProps,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn,
} from '@growthlytic/web-shared-common';
import { ChevronRight } from 'lucide-react';
import * as React from 'react';

type AdTreeProps = {
  title: string;
  nodes: AdTreeNodeProps['node'][];
  onCheckedChange: AdTreeNodeProps['onCheckedChange'];
};

function AdTree({ title, nodes, onCheckedChange }: AdTreeProps) {
  return (
    <div className="flex h-full w-64 flex-col gap-y-2 rounded px-2 py-4">
      <div className="px-2">
        <p className="text-sidebar-foreground/70 text-xs font-medium">
          {title}
        </p>
      </div>
      <div>
        {nodes.map((node) => (
          <AdTreeNode
            isRoot
            key={node.name}
            node={node}
            onCheckedChange={onCheckedChange}
          />
        ))}
      </div>
    </div>
  );
}

type AdTreeNodeProps = {
  isRoot?: boolean;
  node: {
    checked: CheckboxProps['checked'];
    children?: AdTreeNodeProps['node'][];
    disabled?: boolean;
    id: string;
    name: string;
    type: Exclude<AdTreeLevel, 'root' | 'adAccount'>;
    Icon: React.FC<React.ComponentProps<'svg'>>;
  };
  onCheckedChange: (payload: {
    id: string;
    type: Exclude<AdTreeLevel, 'root' | 'adAccount'>;
    checked: CheckboxProps['checked'];
  }) => void;
};

function AdTreeNode({
  isRoot = false,
  node,
  onCheckedChange,
}: AdTreeNodeProps) {
  const { checked, children, disabled, id, name, type, Icon } = node;
  const isLeaf = children === undefined || children.length === 0;

  return (
    <Collapsible
      className={cn(
        '[&[data-state=open]>div>button>span>svg:first-child]:rotate-90',
        !isRoot && 'border-l border-gray-200 pl-2',
      )}
    >
      <CollapsibleTrigger asChild>
        <div className="grid w-full grid-cols-[1fr_auto] items-center gap-x-2 pr-2">
          <Button
            className="w-full justify-start truncate px-1.5"
            size="sm"
            variant="ghost"
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="inline-flex gap-x-1.5">
                    <ChevronRight
                      className={cn('h-4 w-4', isLeaf && 'hidden')}
                    />
                    <Icon className="h-4 w-4" />
                    {name}
                  </span>
                </TooltipTrigger>
                <TooltipContent sideOffset={2}>
                  <p>{name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
          <span onClick={(event) => void event.stopPropagation()}>
            <Checkbox
              checked={checked}
              disabled={disabled}
              onCheckedChange={(checked) =>
                onCheckedChange({ id, type, checked })
              }
            />
          </span>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent asChild>
        <div className="pl-3.5">
          {children?.map((child) => (
            <AdTreeNode
              key={child.id}
              node={child}
              onCheckedChange={onCheckedChange}
            />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

export type { AdTreeProps };
export { AdTree, AdTreeNode };
