import type { Ad } from '@growthlytic/shared-ad';
import { type GetAdsPayload, getAds } from '@growthlytic/web-shared-common';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

export type AdEntity = {
  allIds: Set<Ad['id']>;
  byId: Record<string, Ad>;
  byAdAccountId: Record<string, Ad['id'][]>;
  byAdCampaignId: Record<string, Ad[]>;
  byAdSetId: Record<string, Ad[]>;
};

type UseGetAdEntityQueryOptions = UseQueryOptions<
  Awaited<AdEntity>,
  unknown,
  Awaited<AdEntity>,
  string[]
>;

export const useGetAdEntityQuery = (
  payload: GetAdsPayload,
  options?: Omit<UseGetAdEntityQueryOptions, 'queryFn' | 'queryKey'>,
) =>
  useQuery({
    queryKey: ['adAccounts', payload.adAccountIds.join(','), 'ads'],
    queryFn: async () => createAdEntity(await getAds(payload)),
    ...options,
  });

const createAdEntity = (data: Ad[] | undefined) => {
  const entity: AdEntity = {
    allIds: new Set(),
    byId: {},
    byAdAccountId: {},
    byAdCampaignId: {},
    byAdSetId: {},
  };

  if (data === undefined) return entity;

  data.forEach((item) => {
    entity.allIds.add(item.id);
    entity.byId[item.id] = item;

    if (entity.byAdAccountId[item.adAccountId] === undefined)
      entity.byAdAccountId[item.adAccountId] = [];
    entity.byAdAccountId[item.adAccountId]?.push(item.id);

    if (entity.byAdCampaignId[item.adCampaign.id] === undefined)
      entity.byAdCampaignId[item.adCampaign.id] = [];
    entity.byAdCampaignId[item.adCampaign.id]?.push(item);

    if (entity.byAdSetId[item.adSet.id] === undefined)
      entity.byAdSetId[item.adSet.id] = [];
    entity.byAdSetId[item.adSet.id]?.push(item);
  });

  return entity;
};
