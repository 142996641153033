import { useAuthContext } from '@growthlytic/web-users';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeTree } from './route-tree.gen';

const router = createRouter({
  context: {
    auth: undefined,
  },
  routeTree,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function AppRouter() {
  const authContext = useAuthContext();

  return (
    <RouterProvider
      context={{
        auth: authContext,
      }}
      router={router}
    />
  );
}
