import { LoadingPage } from '@growthlytic/web-shared-common';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuthContext } from '../hooks/use-auth-context';

export function AuthSignOutPage() {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await authContext.handleSignOut();
      navigate({
        from: '/auth/sign-out',
        to: '/auth/sign-in',
      });
    })();
  }, [authContext, navigate]);

  return <LoadingPage text="Signing out..." />;
}
