import { AsyncState } from '@growthlytic/shared-common';
import { User } from '@growthlytic/shared-user';
import {
  LoadingPage,
  auth,
  getCurrentUser,
} from '@growthlytic/web-shared-common';
import {
  browserLocalPersistence,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailLink,
  signOut,
} from 'firebase/auth';
import { isNil } from 'lodash';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/auth-context';

const EMAIL_STORAGE_KEY = 'userEmail';

export function AuthProvider({ children }: PropsWithChildren) {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [state, setState] = useState<
    AsyncState<{ user: User | null; isEmailLinkSent: boolean }>
  >({
    status: 'idle',
  });

  const handleEmailLinkSend = async ({ email }: { email: string }) => {
    setState({ status: 'pending' });

    try {
      await sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/auth/verify-email-link`,
        handleCodeInApp: true,
      });

      setState({
        status: 'success',
        data: {
          isEmailLinkSent: true,
          user: null,
        },
      });

      localStorage.setItem(EMAIL_STORAGE_KEY, email);
    } catch (error) {
      setState({
        status: 'error',
        error,
      });
    }
  };

  const handleEmailLinkSignInOrSignUp = async ({
    emailLink,
  }: {
    emailLink: string;
  }) => {
    setState({ status: 'pending' });

    if (!isSignInWithEmailLink(auth, emailLink)) {
      setState({
        status: 'error',
        error: new Error('Invalid email link'),
      });
      return;
    }

    const email = localStorage.getItem(EMAIL_STORAGE_KEY);
    if (email === null) {
      setState({
        status: 'error',
        error: new Error('Invalid email link'),
      });
      return;
    }

    try {
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailLink(auth, email, emailLink);

      setState({
        status: 'success',
        data: {
          isEmailLinkSent: true,
          user: await getCurrentUser(),
        },
      });
    } catch (error) {
      setState({
        status: 'error',
        error,
      });
    } finally {
      localStorage.removeItem(EMAIL_STORAGE_KEY);
    }
  };

  const handleSignOut = async () => {
    setState({ status: 'pending' });

    try {
      await signOut(auth);

      setState({
        status: 'success',
        data: {
          isEmailLinkSent: false,
          user: null,
        },
      });
    } catch (error) {
      setState({
        status: 'error',
        error,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (firebaseUser) => {
        const user = isNil(firebaseUser) ? null : await getCurrentUser();
        setState({
          status: 'success',
          data: {
            isEmailLinkSent: false,
            user,
          },
        });
        setIsInitialized(true);
      },
      (error) => {
        setState({ status: 'error', error });
        setIsInitialized(true);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  if (!isInitialized) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...(state.data?.user
          ? { isAuthenticated: true, user: state.data.user }
          : { isAuthenticated: false, user: null }),
        isEmailLinkSent: state.data?.isEmailLinkSent ?? false,
        status: state.status,
        handleEmailLinkSend,
        handleEmailLinkSignInOrSignUp,
        handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
