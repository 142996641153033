import type { FC } from 'react';
import { chartConfigToChartStyleInnerHtml } from './chart-utils';
import type { ChartConfig } from './chart.types';

type ChartStyleProps = {
  id: string;
  config: ChartConfig;
};

const ChartStyle: FC<ChartStyleProps> = ({ id, config }) => {
  const innerHtml = chartConfigToChartStyleInnerHtml(id, config);

  if (innerHtml === undefined) {
    return null;
  }
  return <style dangerouslySetInnerHTML={{ __html: innerHtml }} />;
};
ChartStyle.displayName = 'ChartStyle';

export type { ChartStyleProps };
export { ChartStyle };
