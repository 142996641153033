import { AdInsight } from '@growthlytic/shared-ad';
import {
  type DateRange,
  type HttpResponseEnvelopeJson,
  type Interval,
  createSerializableDateRange,
} from '@growthlytic/shared-common';
import { env } from '../utils/env';
import { httpClient } from '../utils/http-client';

export type GetAdInsightsPayload = {
  accessToken: string;
  adAccountIdToAdIds: Record<string, string[]>;
  dateRange: DateRange;
  interval: Interval;
};

export const getAdInsights = ({
  accessToken,
  adAccountIdToAdIds,
  dateRange,
  interval,
}: GetAdInsightsPayload) =>
  httpClient
    .get<HttpResponseEnvelopeJson<AdInsight[]>>(
      `${env.service.baseUrl}/v1/ad-insights`,
      {
        searchParams: {
          accessToken,
          adAccountIdToAdIds: JSON.stringify(adAccountIdToAdIds),
          dateRange: JSON.stringify(createSerializableDateRange(dateRange)),
          interval,
        },
      },
    )
    .json()
    .then((response) => response.data);
