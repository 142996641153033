import ky from 'ky';
import { v4 } from 'uuid';
import { auth } from './firebase';

export const httpClient = ky.create({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Request-Id', v4());
      },

      async (request) => {
        const currentUser = auth.currentUser;

        if (!currentUser) return;
        const token = await auth.currentUser?.getIdToken();

        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});
