import {
  type GetAdMetricsPayload,
  getAdMetrics,
} from '@growthlytic/web-shared-common';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

type UseGetAdMetricsQueryOptions = UseQueryOptions<
  Awaited<ReturnType<typeof getAdMetrics>>,
  unknown,
  Awaited<ReturnType<typeof getAdMetrics>>,
  string[]
>;

export const useGetAdMetricsQuery = (
  payload: GetAdMetricsPayload,
  options?: Omit<UseGetAdMetricsQueryOptions, 'queryFn' | 'queryKey'>,
) =>
  useQuery({
    queryKey: [
      'adAccountIdToAdIds',
      JSON.stringify(payload.adAccountIdToAdIds),
      'adTreeLevel',
      JSON.stringify(payload.adTreeLevel),
      'dateRanges',
      JSON.stringify(payload.dateRanges),
    ],
    queryFn: () => getAdMetrics(payload),
    ...options,
  });
