import type { DateRange, Interval } from '@growthlytic/shared-common';
import { isEqual, subDays } from 'date-fns';
import { useState } from 'react';
import type { DateRange as ReactDayPickerDateRange } from 'react-day-picker';

export const dateRangePresets = [
  'last7Days',
  'last14Days',
  'last30Days',
  'last90Days',
] as const;
export type DateRangePreset = (typeof dateRangePresets)[number];

export type UseDateRangeFilters = {
  initialInterval: Interval;
  initialDateRange: DateRange;
  initialDateRangePreset: DateRangePreset;
};

export const useDateRangeFilters = (options: UseDateRangeFilters) => {
  const [selectedInterval, setSelectedInterval] = useState<Interval>(
    options.initialInterval,
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    options.initialDateRange,
  );
  const [selectedDateRangePreset, setSelectedDateRangePreset] = useState<
    DateRangePreset | undefined
  >(options.initialDateRangePreset);

  const handleIntervalChange = ({ interval }: { interval: Interval }) => {
    setSelectedInterval(interval);
  };

  const handleDateRangeSelect = ({
    dateRange,
    selectedDate,
    selectionType,
  }:
    | {
        dateRange: ReactDayPickerDateRange | undefined;
        selectedDate: Date;
        selectionType: 'custom';
      }
    | {
        dateRange: DateRange;
        selectedDate?: undefined;
        selectionType: 'preset';
      }) => {
    if (selectionType === 'preset') {
      setSelectedDateRange(dateRange);
      return;
    }

    if (
      dateRange === undefined ||
      dateRange.from === undefined ||
      dateRange.to === undefined ||
      !isEqual(selectedDateRange.since, selectedDateRange.until)
    ) {
      setSelectedDateRange({
        since: selectedDate,
        until: selectedDate,
      });
      return;
    }

    if (selectedDate.getTime() > selectedDateRange.since.getTime()) {
      setSelectedDateRange({
        since: selectedDateRange.since,
        until: selectedDate,
      });
      return;
    }

    setSelectedDateRange({
      since: selectedDate,
      until: selectedDateRange.until,
    });
  };

  const handleDateRangePresetChange = (dateRangePreset: DateRangePreset) => {
    setSelectedDateRangePreset(dateRangePreset);
    handleDateRangeSelect({
      selectionType: 'preset',
      dateRange: dateRangePresetToDateRange[dateRangePreset],
    });
  };

  return {
    selectedInterval,
    selectedDateRange,
    selectedDateRangePreset,
    handleIntervalChange,
    handleDateRangeSelect,
    handleDateRangePresetChange,
  } as const;
};

export const dateRangePresetToDateRange = {
  last7Days: { since: subDays(new Date(), 7), until: subDays(new Date(), 1) },
  last14Days: { since: subDays(new Date(), 14), until: subDays(new Date(), 1) },
  last30Days: { since: subDays(new Date(), 30), until: subDays(new Date(), 1) },
  last90Days: { since: subDays(new Date(), 90), until: subDays(new Date(), 1) },
} as const satisfies Record<DateRangePreset, DateRange>;
