import { AdAccount } from '@growthlytic/shared-ad';
import { HttpResponseEnvelopeJson } from '@growthlytic/shared-common';
import { env } from '../utils/env';
import { httpClient } from '../utils/http-client';

export type GetAdAccountsPayload = {
  accessToken: string;
  userId: string;
};

export const getAdAccounts = ({ userId, accessToken }: GetAdAccountsPayload) =>
  httpClient
    .get<HttpResponseEnvelopeJson<AdAccount[]>>(
      `${env.service.baseUrl}/v1/ad-accounts`,
      {
        searchParams: { accessToken, userId },
      },
    )
    .json()
    .then((response) => response.data);
