import { Outlet, createFileRoute } from '@tanstack/react-router'
import { DashboardLayout } from './-components/dashboard-layout'

const Route = createFileRoute('/_protected/dashboard/_dashboard')({
  component: () => (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ),
})

export { Route }
