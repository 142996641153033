import { AdMetricName } from '@growthlytic/shared-ad';
import { useState } from 'react';

type UseAdMetricFiltersOptions = {
  initialSelectedAdMetric: AdMetricName;
};

const useAdMetricFilters = ({
  initialSelectedAdMetric,
}: UseAdMetricFiltersOptions) => {
  const [selectedAdMetric, setSelectedAdMetric] = useState<AdMetricName>(
    initialSelectedAdMetric,
  );

  const handleSelectedAdMetricChange = ({
    selectedAdMetric,
  }: {
    selectedAdMetric: AdMetricName;
  }) => {
    setSelectedAdMetric(selectedAdMetric);
  };

  return {
    handleSelectedAdMetricChange,
    selectedAdMetric,
  } as const;
};

export { useAdMetricFilters };
