import { createFileRoute } from '@tanstack/react-router'
import {
  DashboardPage,
  DashboardPageBody,
  DashboardPageHeader,
  DashboardPageHeaderTitle,
} from './-components/dashboard-page'

const Route = createFileRoute('/_protected/dashboard/_dashboard/ads')({
  component: DashboardAdsPage,
})

function DashboardAdsPage() {
  return (
    <DashboardPage>
      <DashboardPageHeader>
        <DashboardPageHeaderTitle>Ads</DashboardPageHeaderTitle>
      </DashboardPageHeader>
      <DashboardPageBody></DashboardPageBody>
    </DashboardPage>
  )
}

export { Route }
