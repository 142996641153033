import {
  AdMetricName,
  AdTimeSeriesMetricDataPoint,
  AdTimeSeriesMetrics,
  adMetricNames,
  calculateIncrementPercentage,
  isMoneyAdTimeSeriesMetricDataPoint,
} from '@growthlytic/shared-ad'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartProvider,
  ChartTooltip,
  ChartTooltipContent,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@growthlytic/web-shared-common'
import { createFileRoute } from '@tanstack/react-router'
import { differenceInCalendarDays } from 'date-fns'
import {
  BanknoteIcon,
  ChartColumnIncreasingIcon,
  HandCoinsIcon,
  LucideIcon,
  MessageSquareMoreIcon,
  MousePointerClickIcon,
  SpeechIcon,
} from 'lucide-react'
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts'
import {
  AdAccountDropdownMenu,
  AdAccountDropdownMenuContent,
  AdAccountDropdownMenuTrigger,
} from '../-components/ad-account-dropdown-menu'
import {
  DateRangeDropdownMenu,
  DateRangeDropdownMenuBody,
  DateRangeDropdownMenuTrigger,
} from '../-components/date-range-dropdown-menu'
import {
  DashboardPage,
  DashboardPageBody,
  DashboardPageHeader,
  DashboardPageHeaderTitle,
} from '../../-components/dashboard-page'
import {
  FacebookApiKeyDialog,
  FacebookApiKeyDialogBody,
  FacebookApiKeyDialogTrigger,
} from '../../-components/facebook-api-key-dialog'
import { useDashboardAdSelectionFilters } from '../../-hooks/use-dashboard-ad-selection-filters'
import { useDashboardOverviewPageEntities } from '../../-hooks/use-dashboard-overview-page-entities'
import {
  dateRangePresetToDateRange,
  useDateRangeFilters,
} from '../../-hooks/use-date-range-filters'
import { useFacebookApiKey } from '../../-hooks/use-facebook-api-key'
import {
  AdMetricOverviewCard,
  AdMetricOverviewCardProps,
} from './-components/ad-metric-overview-card'
import { useAdMetricFilters } from './-hooks/use-ad-metric-filters'

const Route = createFileRoute('/_protected/dashboard/_dashboard/overview/')({
  component: DashboardOverviewPage,
})

function DashboardOverviewPage() {
  const {
    state: { status: facebookApiKeyStatus, value: facebookApiKey },
    handleChange,
    handleSubmit,
  } = useFacebookApiKey()

  const {
    handleIntervalChange,
    handleDateRangeSelect,
    handleDateRangePresetChange,
    selectedInterval,
    selectedDateRange,
    selectedDateRangePreset,
  } = useDateRangeFilters({
    initialInterval: '1d',
    initialDateRange: dateRangePresetToDateRange['last7Days'],
    initialDateRangePreset: 'last7Days',
  })

  const { handleSelectedAdMetricChange, selectedAdMetric } = useAdMetricFilters(
    {
      initialSelectedAdMetric: 'messagingConversationStarted',
    },
  )

  const { selectedAdAccounts, handleSelectedAdAccountChange } =
    useDashboardAdSelectionFilters()

  const {
    adAccountEntityQuery,
    adTimeSeriesMetricQuery,
    monthlyAdTimeSeriesMetricQuery,
  } = useDashboardOverviewPageEntities({
    facebookApiKey,
    selectedAdAccounts,
    selectedDateRange: selectedDateRange,
  })

  return (
    <DashboardPage>
      <DashboardPageHeader>
        <DashboardPageHeaderTitle>Overview</DashboardPageHeaderTitle>
        <div className="flex items-center gap-x-2">
          <FacebookApiKeyDialog>
            <FacebookApiKeyDialogTrigger />
            <FacebookApiKeyDialogBody
              state={{ status: facebookApiKeyStatus, value: facebookApiKey }}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </FacebookApiKeyDialog>
          <DateRangeDropdownMenu
            handleIntervalChange={handleIntervalChange}
            handleDateRangeSelect={handleDateRangeSelect}
            handleDateRangePresetChange={handleDateRangePresetChange}
            selectedInterval={selectedInterval}
            selectedDateRange={selectedDateRange}
            selectedDateRangePreset={selectedDateRangePreset}
          >
            <DateRangeDropdownMenuTrigger />
            <DateRangeDropdownMenuBody />
          </DateRangeDropdownMenu>
        </div>
      </DashboardPageHeader>

      <DashboardPageBody className="space-y-4">
        <AdAccountDropdownMenu
          data={adAccountEntityQuery.data}
          handleSelectedAdAccountChange={handleSelectedAdAccountChange}
          selectedAdAccounts={selectedAdAccounts}
          status={adAccountEntityQuery.status}
        >
          <AdAccountDropdownMenuTrigger />
          <AdAccountDropdownMenuContent />
        </AdAccountDropdownMenu>

        <div className="grid grid-cols-4 gap-4">
          {createOverviewCardPropsList({
            adTimeSeriesMetrics: adTimeSeriesMetricQuery.data,
            includedMetricNames: ['reach', 'spend', 'ctr', 'costPerResult'],
          }).map((props) => (
            <AdMetricOverviewCard key={props.title} {...props} />
          ))}
        </div>

        <div className="grid grid-cols-12">
          <Card className="col-span-8 grid-rows-[auto_1fr]">
            <CardHeader className="flex flex-col items-center space-y-0 border-b p-6 sm:flex-row">
              <div className="flex flex-1 flex-col justify-center gap-1">
                <CardTitle>
                  {adMetricNameToDisplayTitle[selectedAdMetric]}
                </CardTitle>
                <CardDescription>
                  {adMetricNameToDisplayDescription[selectedAdMetric]}
                </CardDescription>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    <ChartColumnIncreasingIcon className="mr-1 h-5 w-5" />
                    Metric
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48" align="end">
                  <DropdownMenuGroup>
                    {adMetricNames.map((adMetricName) => (
                      <DropdownMenuCheckboxItem
                        key={adMetricName}
                        checked={adMetricName === selectedAdMetric}
                        onCheckedChange={() =>
                          handleSelectedAdMetricChange({
                            selectedAdMetric: adMetricName,
                          })
                        }
                      >
                        {adMetricNameToDisplayTitle[adMetricName]}
                      </DropdownMenuCheckboxItem>
                    ))}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>{' '}
            </CardHeader>
            <CardContent className="px-2 sm:p-6">
              <ChartProvider
                config={{
                  bar: {
                    color: 'hsl(var(--chart-1))',
                  },
                }}
              >
                <BarChart
                  accessibilityLayer
                  data={monthlyAdTimeSeriesMetricQuery.data?.[
                    selectedAdMetric
                  ].dataPoints.map((dataPoint) => ({
                    date: dataPoint.dateRange.until,
                    value: isMoneyAdTimeSeriesMetricDataPoint(dataPoint)
                      ? dataPoint.value.amount
                      : dataPoint.value,
                  }))}
                  margin={{
                    left: 12,
                    right: 12,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    minTickGap={32}
                    tickFormatter={(value) => {
                      const date = new Date(value)
                      return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                      })
                    }}
                  />
                  <ChartTooltip
                    content={
                      <ChartTooltipContent
                        className="w-[150px]"
                        nameKey="views"
                        labelFormatter={(value) => {
                          return new Date(value).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          })
                        }}
                      />
                    }
                  />
                  <Bar dataKey="value" fill={`var(--color-bar})`} />
                </BarChart>
              </ChartProvider>
            </CardContent>
          </Card>
        </div>
      </DashboardPageBody>
    </DashboardPage>
  )
}

const createOverviewCardPropsList = ({
  adTimeSeriesMetrics,
  includedMetricNames,
}: {
  adTimeSeriesMetrics?: AdTimeSeriesMetrics
  includedMetricNames: AdMetricName[]
}): AdMetricOverviewCardProps[] =>
  includedMetricNames.map((metricName) => {
    const metric = adTimeSeriesMetrics?.[metricName]

    const currentDataPoint = metric?.dataPoints.at(-1)
    const previousDataPoint = metric?.dataPoints.at(-2)

    return {
      title: adMetricNameToDisplayTitle[metricName],
      description: getOverviewCardDescription({
        currentDataPoint,
        previousDataPoint,
      }),
      value: getOverviewCardValue(currentDataPoint),
      Icon: adMetricNameToDisplayIcon[metricName],
    }
  })

const getOverviewCardValue = (
  dataPoint?: AdTimeSeriesMetricDataPoint,
): string => {
  if (dataPoint === undefined) return '-'

  switch (dataPoint.unit) {
    case 'money':
      return `RM ${dataPoint.value.amount.toFixed(2)}`
    case 'percentage':
      return `${dataPoint.value.toFixed(2)}%`
    case 'count':
      return dataPoint.value.toFixed(2)
  }
}

const getOverviewCardDescription = ({
  currentDataPoint,
  previousDataPoint,
}: {
  currentDataPoint?: AdTimeSeriesMetricDataPoint
  previousDataPoint?: AdTimeSeriesMetricDataPoint
}) => {
  if (currentDataPoint === undefined || previousDataPoint === undefined)
    return 'Not enough data'

  const incrementPercentage = calculateIncrementPercentage({
    current: currentDataPoint,
    previous: previousDataPoint,
  })

  const sign = incrementPercentage >= 0 ? '+' : '-'
  const formattedPercentage = Math.abs(incrementPercentage).toFixed(2)
  const days =
    differenceInCalendarDays(
      currentDataPoint.dateRange.until,
      currentDataPoint.dateRange.since,
    ) + 1

  return `${sign}${formattedPercentage}% from last ${days} days`
}

const adMetricNameToDisplayTitle = {
  clicks: 'Clicks (All)',
  costPerResult: 'Cost per Result',
  ctr: 'CTR',
  impressions: 'Impressions',
  messagingConversationStarted: 'Messaging Conversation Started',
  reach: 'Reach',
  spend: 'Spend',
} as const satisfies Record<AdMetricName, string>

const adMetricNameToDisplayDescription = {
  clicks: 'Total number of clicks on your ads',
  costPerResult: 'Average cost per desired outcome',
  ctr: 'Click-through rate - Percentage of impressions that resulted in clicks',
  impressions: 'Number of times your ads were displayed',
  messagingConversationStarted:
    'Number of conversations initiated through your ads',
  reach: 'Unique people who saw your ads',
  spend: 'Total amount spent on your ad campaigns',
} as const satisfies Record<AdMetricName, string>

const adMetricNameToDisplayIcon = {
  clicks: MousePointerClickIcon,
  costPerResult: HandCoinsIcon,
  ctr: MousePointerClickIcon,
  impressions: SpeechIcon,
  messagingConversationStarted: MessageSquareMoreIcon,
  reach: SpeechIcon,
  spend: BanknoteIcon,
} as const satisfies Record<AdMetricName, LucideIcon>

export { Route }
