import {
  Button,
  Calendar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Separator,
  ToggleGroup,
  ToggleGroupItem,
  cn,
} from '@growthlytic/web-shared-common';
import { format, subDays, subMonths } from 'date-fns';
import { CalendarRangeIcon } from 'lucide-react';
import { PropsWithChildren, createContext, useContext } from 'react';
import {
  DateRangePreset,
  dateRangePresets,
  useDateRangeFilters,
} from '../../-hooks/use-date-range-filters';

const DISPLAYED_DATETIME_PATTERN = 'dd MMM yyyy' as const;

type DateRangeDropdownMenuContextValue = ReturnType<
  typeof useDateRangeFilters
> & { intervalDisabled: boolean; presetDisabled: boolean };

const DateRangeDropdownMenuContext = createContext<
  DateRangeDropdownMenuContextValue | undefined
>(undefined);

export function DateRangeDropdownMenu({
  children,
  intervalDisabled = false,
  presetDisabled = false,
  ...props
}: PropsWithChildren<
  ReturnType<typeof useDateRangeFilters> & {
    intervalDisabled?: boolean;
    presetDisabled?: boolean;
  }
>) {
  return (
    <DropdownMenu>
      <DateRangeDropdownMenuContext.Provider
        value={{
          intervalDisabled,
          presetDisabled,
          ...props,
        }}
      >
        {children}
      </DateRangeDropdownMenuContext.Provider>
    </DropdownMenu>
  );
}

export function DateRangeDropdownMenuTrigger() {
  const { selectedDateRange } = useDateRangeDropdownMenuContext();

  return (
    <DropdownMenuTrigger asChild>
      <Button variant="outline">
        <CalendarRangeIcon className="mr-1 h-5 w-5" />
        {`${format(selectedDateRange.since, DISPLAYED_DATETIME_PATTERN)} - ${format(selectedDateRange.until, DISPLAYED_DATETIME_PATTERN)}`}
      </Button>
    </DropdownMenuTrigger>
  );
}

export function DateRangeDropdownMenuBody() {
  const {
    handleIntervalChange,
    handleDateRangeSelect,
    handleDateRangePresetChange,
    intervalDisabled,
    presetDisabled,
    selectedInterval,
    selectedDateRange,
    selectedDateRangePreset,
  } = useDateRangeDropdownMenuContext();

  return (
    <DropdownMenuContent align="end" className="w-64 sm:w-[31rem]">
      <div className="p-2">
        <Calendar
          defaultMonth={subMonths(selectedDateRange.until, 1)}
          disabled={{
            after: subDays(new Date(), 1),
          }}
          mode="range"
          onSelect={(selectedDateRange, selectedDate) =>
            handleDateRangeSelect({
              selectionType: 'custom',
              dateRange: selectedDateRange,
              selectedDate,
            })
          }
          required
          selected={{
            from: selectedDateRange.since,
            to: selectedDateRange.until,
          }}
          numberOfMonths={2}
        />
      </div>
      <div className="max-w-full">
        <div
          className={cn(
            'flex items-center gap-x-3 px-4 py-2',
            intervalDisabled && 'hidden',
          )}
        >
          <p className="text-sm font-medium text-gray-700">Interval</p>
          <Separator className="h-4" orientation="vertical" />
          <ToggleGroup
            className="inline-flex gap-x-2"
            onValueChange={(selectedInterval) =>
              handleIntervalChange({
                interval: selectedInterval as Interval,
              })
            }
            value={selectedInterval}
            type="single"
          >
            <ToggleGroupItem size="sm" value="1d" variant="outline">
              Daily
            </ToggleGroupItem>
            <ToggleGroupItem size="sm" value="1M" variant="outline">
              Monthly
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
        <div
          className={cn(
            'flex items-start gap-x-3 px-4 py-2',
            presetDisabled && 'hidden',
          )}
        >
          <p className="pt-1.5 text-sm font-medium text-gray-700">Preset</p>
          <Separator className="mt-2 h-4" orientation="vertical" />
          <ToggleGroup
            className="flex flex-wrap justify-start gap-x-2"
            onValueChange={handleDateRangePresetChange}
            value={selectedDateRangePreset}
            type="single"
          >
            {dateRangePresets.map((preset) => (
              <ToggleGroupItem
                key={preset}
                size="sm"
                value={preset}
                variant="outline"
              >
                {dateRangePresetToTitle[preset]}
              </ToggleGroupItem>
            ))}
          </ToggleGroup>
        </div>
      </div>
    </DropdownMenuContent>
  );
}

const useDateRangeDropdownMenuContext = () => {
  const context = useContext(DateRangeDropdownMenuContext);
  if (context === undefined)
    throw new Error(
      'useDateRangeDropdownMenuContext must be used within DateRangeDropdownMenuContext.Provider',
    );
  return context;
};

const dateRangePresetToTitle = {
  last7Days: 'Last 7 days',
  last14Days: 'Last 14 days',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
} as const satisfies Record<DateRangePreset, string>;
