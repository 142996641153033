import { LoaderCircleIcon } from 'lucide-react';

export function LoadingPage({ text = 'Loading...' }: { text?: string }) {
  return (
    <div className="flex h-full w-full items-center justify-center gap-2">
      <LoaderCircleIcon className="h-5 w-5 animate-spin" />
      <span>{text}</span>
    </div>
  );
}
