import {
  type GetCurrentFacebookUserPayload,
  getCurrentFacebookUser,
} from '@growthlytic/web-shared-common';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

type UseGetCurrentFacebookUserQueryOptions = UseQueryOptions<
  Awaited<ReturnType<typeof getCurrentFacebookUser>>,
  unknown,
  Awaited<ReturnType<typeof getCurrentFacebookUser>>,
  string[]
>;

export const useGetCurrentFacebookUserQuery = (
  payload: GetCurrentFacebookUserPayload,
  options?: Omit<UseGetCurrentFacebookUserQueryOptions, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: ['facebook-users', 'current'],
    queryFn: () => getCurrentFacebookUser(payload),
    ...options,
  });
