import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  cn,
} from '@growthlytic/web-shared-common';
import { Link, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuthContext } from '../hooks/use-auth-context';
import { EmailFormData, useEmailForm } from '../hooks/use-email-form';

export function AuthSignUpPage() {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const form = useEmailForm();

  const handleSubmit = async ({ email }: EmailFormData) => {
    console.log(email);
    await authContext.handleEmailLinkSend({ email });
  };

  useEffect(() => {
    if (authContext.isAuthenticated)
      navigate({
        from: '/auth/sign-up',
        to: '/dashboard/overview',
      });
  }, [authContext, navigate]);

  return (
    <Form {...form}>
      <form
        className="flex h-full w-full items-center justify-center px-2"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className="w-full max-w-sm space-y-4">
          <h1 className="text-2xl font-bold">Sign Up</h1>
          <div className="space-y-6">
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        disabled={authContext.status === 'pending'}
                        placeholder="john.doe@example.com"
                        required
                        type="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p
                className={cn(
                  'hidden p-1 text-xs text-green-600',
                  authContext.isEmailLinkSent && 'block',
                )}
              >
                A sign up link has been sent to your email
              </p>
            </div>
            <Button
              disabled={authContext.status === 'pending'}
              type="submit"
              className="w-full"
            >
              Sign Up
            </Button>
          </div>
          <div className="text-center text-sm">
            Already have an account?{' '}
            <Link
              disabled={authContext.status === 'pending'}
              href="/auth/sign-in"
              className="underline"
            >
              Sign in
            </Link>
          </div>
        </div>
      </form>
    </Form>
  );
}
