import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useIsMobile,
} from '@growthlytic/web-shared-common';
import { Link } from '@tanstack/react-router';
import {
  ChartLineIcon,
  ChevronsUpDown,
  Command,
  FolderTreeIcon,
  LogOut,
  Settings,
  TableIcon,
  UserRound,
} from 'lucide-react';
import { type FC, Fragment } from 'react';

const user = {
  avatar: '#',
  email: 'tattzetey@gmail.com',
  name: 'Liam Tey',
} as const;

const avatarDropdownSections = [
  {
    id: 'section1',
    items: [
      {
        href: '/dashboard/account',
        title: 'account',
        IconElement: UserRound,
      },
      {
        href: '/dashboard/setting',
        title: 'setting',
        IconElement: Settings,
      },
    ],
  },
  {
    id: 'section2',
    items: [
      {
        href: '/auth/logout',
        title: 'Logout',
        IconElement: LogOut,
      },
    ],
  },
] as const;

const navigations = [
  {
    name: 'Overview',
    href: '/dashboard/overview',
    IconComponent: ChartLineIcon,
  },
  {
    name: 'Ads',
    href: '/dashboard/ads',
    IconComponent: TableIcon,
  },
  {
    name: 'Comparison',
    href: '/dashboard/comparison',
    IconComponent: FolderTreeIcon,
  },
] as const satisfies {
  name: string;
  href: string;
  IconComponent: FC;
}[];

const DashboardSidebar: FC = () => {
  const isMobile = useIsMobile();

  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <Link href="#">
                <div className="bg-sidebar-primary text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                  <Command className="size-4" />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    Edge Point Solution
                  </span>
                  <span className="truncate text-xs">Agency</span>
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup className="group-data-[collapsible=icon]:hidden">
          <SidebarMenu>
            {navigations.map(({ href, name, IconComponent }) => (
              <SidebarMenuItem key={name}>
                <SidebarMenuButton asChild>
                  <Link href={href}>
                    <IconComponent />
                    <span>{name}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <Avatar className="h-8 w-8 rounded-lg">
                    <AvatarImage src={user.avatar} alt={user.name} />
                    <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                  </Avatar>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{user.name}</span>
                    <span className="truncate text-xs">{user.email}</span>
                  </div>
                  <ChevronsUpDown className="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                side={isMobile ? 'bottom' : 'right'}
                align="end"
                sideOffset={4}
              >
                <DropdownMenuLabel className="p-0 font-normal">
                  <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarImage src={user.avatar} alt={user.name} />
                      <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {user.name}
                      </span>
                      <span className="truncate text-xs">{user.email}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {avatarDropdownSections.map((section, index) => (
                  <Fragment key={section.id}>
                    <DropdownMenuGroup>
                      {section.items.map(({ href, title, IconElement }) => (
                        <DropdownMenuItem asChild key={title}>
                          <Link href={href}>
                            <IconElement className="mr-2 h-5 w-5" />
                            {title}
                          </Link>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuGroup>
                    {index < avatarDropdownSections.length - 1 ? (
                      <DropdownMenuSeparator />
                    ) : undefined}
                  </Fragment>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
};

export { DashboardSidebar };
