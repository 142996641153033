import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const emailFormDataSchema = z.object({
  email: z.string().email(),
});

export type EmailFormData = z.infer<typeof emailFormDataSchema>;

export const useEmailForm = () => {
  return useForm<EmailFormData>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(emailFormDataSchema),
  });
};
