import type { AdAccount } from '@growthlytic/shared-ad';
import {
  type GetAdAccountsPayload,
  getAdAccounts,
} from '@growthlytic/web-shared-common';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

export type AdAccountEntity = {
  allIds: Set<AdAccount['id']>;
  byId: Record<AdAccount['id'], AdAccount>;
};

type UseGetAdAccountEntityQueryOptions = UseQueryOptions<
  Awaited<AdAccountEntity>,
  unknown,
  Awaited<AdAccountEntity>,
  string[]
>;

export const useGetAdAccountEntityQuery = (
  payload: GetAdAccountsPayload,
  options?: Omit<UseGetAdAccountEntityQueryOptions, 'queryFn' | 'queryKey'>,
) =>
  useQuery({
    queryKey: ['users', payload.userId],
    queryFn: async () => createAdAccountEntity(await getAdAccounts(payload)),
    ...options,
  });

const createAdAccountEntity = (data: AdAccount[] | undefined) => {
  const entity: AdAccountEntity = {
    allIds: new Set(),
    byId: {},
  };

  if (data === undefined) return entity;

  data.forEach((item) => {
    entity.allIds.add(item.id);
    entity.byId[item.id] = item;
  });

  return entity;
};
