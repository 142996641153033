import { cn } from '@growthlytic/web-shared-common';
import type { ComponentProps, PropsWithChildren } from 'react';

function DashboardPage({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<'div'>>) {
  return (
    <div
      className={cn(
        'flex h-full max-w-screen-2xl flex-1 flex-col gap-4 p-4',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

function DashboardPageHeader({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<'div'>>) {
  return (
    <div
      className={cn('flex h-auto items-center justify-between', className)}
      {...props}
    >
      {children}
    </div>
  );
}

function DashboardPageHeaderTitle({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<'h1'>>) {
  return (
    <h1
      className={cn('text-lg font-semibold md:text-2xl', className)}
      {...props}
    >
      {children}
    </h1>
  );
}

function DashboardPageBody({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<'div'>>) {
  return (
    <div className={cn('relative h-full', className)} {...props}>
      {children}
    </div>
  );
}

export {
  DashboardPage,
  DashboardPageHeader,
  DashboardPageHeaderTitle,
  DashboardPageBody,
};
