import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const facebookApiKeyStorageKey = 'facebookApiKey';

const useFacebookApiKey = () => {
  const [state, setState] = useState<{
    status: 'pending' | 'submitted';
    value: string;
  }>(() => {
    const storedValue = sessionStorage.getItem(facebookApiKeyStorageKey);
    return storedValue === null
      ? {
          status: 'pending',
          value: '',
        }
      : {
          status: 'submitted',
          value: storedValue,
        };
  });
  const queryClient = useQueryClient();

  const handleChange = (value: string) => {
    queryClient.invalidateQueries({
      type: 'all',
    });
    setState({ status: 'pending', value });
  };

  const handleSubmit = () => {
    setState({
      status: 'submitted',
      value: state.value,
    });
    sessionStorage.setItem(facebookApiKeyStorageKey, state.value);
  };

  return {
    state,
    handleChange,
    handleSubmit,
  } as const;
};

export { useFacebookApiKey };
