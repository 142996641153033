import {
  useGetAdAccountEntityQuery,
  useGetAdEntityQuery,
  useGetAdInsightsEntityQuery,
} from '@growthlytic/web-ads';
import { useGetCurrentFacebookUserQuery } from '@growthlytic/web-users';
import { useDashboardAdSelectionFilters } from './use-dashboard-ad-selection-filters';
import type { useDateRangeFilters } from './use-date-range-filters';
import type { useFacebookApiKey } from './use-facebook-api-key';

type UseDashboardComparisonPageProps = {
  facebookApiKey?: ReturnType<typeof useFacebookApiKey>['state']['value'];
  selectedAdAccounts: ReturnType<
    typeof useDashboardAdSelectionFilters
  >['selectedAdAccounts'];
  selectedAdCampaigns: ReturnType<
    typeof useDashboardAdSelectionFilters
  >['selectedAdCampaigns'];
  selectedAdSets: ReturnType<
    typeof useDashboardAdSelectionFilters
  >['selectedAdSets'];
  selectedAds: ReturnType<typeof useDashboardAdSelectionFilters>['selectedAds'];
  selectedDateRange: ReturnType<
    typeof useDateRangeFilters
  >['selectedDateRange'];
  selectedInterval: ReturnType<typeof useDateRangeFilters>['selectedInterval'];
};

const useDashboardComparisonPageEntities = ({
  facebookApiKey,
  selectedAdAccounts,
  selectedDateRange,
  selectedInterval,
}: UseDashboardComparisonPageProps) => {
  const getCurrentFacebookUserQuery = useGetCurrentFacebookUserQuery(
    { accessToken: facebookApiKey ?? '' },
    { enabled: facebookApiKey !== undefined && facebookApiKey !== '' },
  );

  const adAccountEntityQuery = useGetAdAccountEntityQuery(
    {
      accessToken: facebookApiKey ?? '',
      userId: getCurrentFacebookUserQuery.data?.id ?? '',
    },
    { enabled: getCurrentFacebookUserQuery.isSuccess },
  );

  const adEntityQuery = useGetAdEntityQuery(
    {
      accessToken: facebookApiKey ?? '',
      adAccountIds: selectedAdAccounts.map((item) => item.id),
    },
    { enabled: selectedAdAccounts.length > 0 },
  );

  const adInsightsEntityQuery = useGetAdInsightsEntityQuery(
    {
      accessToken: facebookApiKey ?? '',
      adAccountIdToAdIds: adEntityQuery.data?.byAdAccountId ?? {},
      dateRange: selectedDateRange,
      interval: selectedInterval,
    },
    { enabled: adEntityQuery.isSuccess },
  );

  return {
    adAccountEntityQuery,
    adEntityQuery,
    adInsightsEntityQuery,
    getCurrentFacebookUserQuery: getCurrentFacebookUserQuery,
  } as const;
};

export { useDashboardComparisonPageEntities };
