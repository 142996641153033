import { createFileRoute } from '@tanstack/react-router'
import {
  DashboardPage,
  DashboardPageHeader,
  DashboardPageHeaderTitle,
} from './-components/dashboard-page'

const Route = createFileRoute('/_protected/dashboard/_dashboard/')({
  component: DashboardIndexPage,
})

function DashboardIndexPage() {
  return (
    <DashboardPage>
      <DashboardPageHeader>
        <DashboardPageHeaderTitle>Welcome back!</DashboardPageHeaderTitle>
      </DashboardPageHeader>
    </DashboardPage>
  )
}

export { Route }
