import { DateRange } from '@growthlytic/shared-common';
import { AdTreeLevel } from '../ad-tree/ad-tree.types';
import {
  AdMetricName,
  AdTimeSeriesMetric,
  AdTimeSeriesMetricDataPoint,
  AdTimeSeriesMetricDataPointAttributes,
  AdTimeSeriesMetrics,
  ComputedAdMetricName,
  CountAdTimeSeriesMetric,
  CountAdTimeSeriesMetricDataPoint,
  CountMetricUnit,
  MetricUnit,
  Money,
  MoneyAdTimeSeriesMetric,
  MoneyAdTimeSeriesMetricDataPoint,
  MoneyMetricUnit,
  PercentageAdTimeSeriesMetric,
  PercentageAdTimeSeriesMetricDataPoint,
  PercentageMetricUnit,
  RawAdMetricName,
  computedAdMetricNames,
  rawAdMetricNames,
} from './ad-metric.types';

type CreateAdTimeSeriesMetricDataPointCommonPayload = {
  attributes: AdTimeSeriesMetricDataPointAttributes;
  dateRange: DateRange<string>;
};
type CreateAdTimeSeriesMetricCommonPayload = {
  name: string;
};

export const createMoneyAdTimeSeriesMetricDataPoint = ({
  attributes,
  dateRange,
  value,
}: CreateAdTimeSeriesMetricDataPointCommonPayload & {
  value: Money;
}): MoneyAdTimeSeriesMetricDataPoint => ({
  attributes,
  dateRange,
  unit: 'money',
  value,
});
export const createPercentageAdTimeSeriesMetricDataPoint = ({
  attributes,
  dateRange,
  value,
}: CreateAdTimeSeriesMetricDataPointCommonPayload & {
  value: number;
}): PercentageAdTimeSeriesMetricDataPoint => ({
  attributes,
  dateRange,
  unit: 'percentage',
  value,
});
export const createCountAdTimeSeriesMetricDataPoint = ({
  attributes,
  dateRange,
  value,
}: CreateAdTimeSeriesMetricDataPointCommonPayload & {
  value: number;
}): CountAdTimeSeriesMetricDataPoint => ({
  attributes,
  dateRange,
  unit: 'count',
  value,
});

export const createAdTimeSeriesMetricDataPointAttributes = ({
  adTreeLevel,
  ...attributes
}: Partial<AdTimeSeriesMetricDataPointAttributes> & {
  adTreeLevel: AdTreeLevel;
}): AdTimeSeriesMetricDataPointAttributes => ({
  adTreeLevel,
  adAccountIds: attributes.adAccountIds ?? [],
  adCampaignIds: attributes.adCampaignIds ?? [],
  adSetIds: attributes.adSetIds ?? [],
  adIds: attributes.adIds ?? [],
});

export const createMoneyAdTimeSeriesMetric = ({
  name,
  dataPoints,
}: CreateAdTimeSeriesMetricCommonPayload & {
  dataPoints: MoneyAdTimeSeriesMetricDataPoint[];
}): MoneyAdTimeSeriesMetric => ({
  name,
  unit: 'money',
  dataPoints,
});
export const createPercentageAdTimeSeriesMetric = ({
  name,
  dataPoints,
}: CreateAdTimeSeriesMetricCommonPayload & {
  dataPoints: PercentageAdTimeSeriesMetricDataPoint[];
}): PercentageAdTimeSeriesMetric => ({
  name,
  unit: 'percentage',
  dataPoints,
});
export const createCountAdTimeSeriesMetric = ({
  name,
  dataPoints,
}: CreateAdTimeSeriesMetricCommonPayload & {
  dataPoints: CountAdTimeSeriesMetricDataPoint[];
}): CountAdTimeSeriesMetric => ({
  name,
  unit: 'count',
  dataPoints,
});

export const createAdCtrTimeSeriesMetric = ({
  adClicksTimeSeriesMetric,
  adImpressionsTimeSeriesMetric,
}: {
  adClicksTimeSeriesMetric: AdTimeSeriesMetrics['clicks'];
  adImpressionsTimeSeriesMetric: AdTimeSeriesMetrics['impressions'];
}): PercentageAdTimeSeriesMetric => {
  if (
    adClicksTimeSeriesMetric.dataPoints.length !==
    adImpressionsTimeSeriesMetric.dataPoints.length
  )
    throw new Error('Invalid input');

  const timeSeriesMetric = createPercentageAdTimeSeriesMetric({
    name: 'ctr',
    dataPoints: [],
  });

  adClicksTimeSeriesMetric.dataPoints.forEach((dataPoint, index) => {
    const clicks = dataPoint.value ?? 0;
    const impressions =
      adImpressionsTimeSeriesMetric.dataPoints[index]?.value ?? 0;

    timeSeriesMetric.dataPoints.push(
      createPercentageAdTimeSeriesMetricDataPoint({
        attributes: cloneAdTimeSeriesMetricDataPointAttributes(
          dataPoint.attributes,
        ),
        dateRange: dataPoint.dateRange,
        value: impressions > 0 ? (clicks / impressions) * 100 : 0,
      }),
    );
  });

  return timeSeriesMetric;
};

export const createAdCostPerResultTimeSeriesMetric = ({
  adSpendTimeSeriesMetric,
  adMessagingConversationStartedTimeSeriesMetric,
}: {
  adSpendTimeSeriesMetric: AdTimeSeriesMetrics['spend'];
  adMessagingConversationStartedTimeSeriesMetric: AdTimeSeriesMetrics['messagingConversationStarted'];
}): MoneyAdTimeSeriesMetric => {
  if (
    adSpendTimeSeriesMetric.dataPoints.length !==
    adMessagingConversationStartedTimeSeriesMetric.dataPoints.length
  )
    throw new Error('Invalid input');

  const timeSeriesMetric = createMoneyAdTimeSeriesMetric({
    name: 'costPerResult',
    dataPoints: [],
  });

  adSpendTimeSeriesMetric.dataPoints.forEach((dataPoint, index) => {
    const spend = dataPoint.value.amount ?? 0;
    const messagingConversationStarted =
      adMessagingConversationStartedTimeSeriesMetric.dataPoints[index]?.value ??
      0;

    timeSeriesMetric.dataPoints.push(
      createMoneyAdTimeSeriesMetricDataPoint({
        attributes: cloneAdTimeSeriesMetricDataPointAttributes(
          dataPoint.attributes,
        ),
        dateRange: dataPoint.dateRange,
        value: {
          amount:
            messagingConversationStarted > 0
              ? spend / messagingConversationStarted
              : 0,
          currency: dataPoint.value.currency,
        },
      }),
    );
  });

  return timeSeriesMetric;
};

export const cloneAdTimeSeriesMetricDataPointAttributes = (
  attributes: AdTimeSeriesMetricDataPointAttributes,
): AdTimeSeriesMetricDataPointAttributes => structuredClone(attributes);

export const calculateIncrementPercentage = ({
  current,
  previous,
}: {
  current: AdTimeSeriesMetricDataPoint;
  previous: AdTimeSeriesMetricDataPoint;
}): number => {
  const currentValue = isMoneyAdTimeSeriesMetricDataPoint(current)
    ? current.value.amount
    : current.value;
  const previousValue = isMoneyAdTimeSeriesMetricDataPoint(previous)
    ? previous.value.amount
    : previous.value;
  return previousValue === 0
    ? 0
    : ((currentValue - previousValue) * 100) / previousValue;
};

export const getAdTimeSeriesMetricDataPointAt = (
  at: number,
  timeSeriesMetric: AdTimeSeriesMetric,
): AdTimeSeriesMetricDataPoint | undefined =>
  timeSeriesMetric.dataPoints.at(at);

export const isMoneyAdTimeSeriesMetric = (
  timeSeriesMetric: AdTimeSeriesMetric,
): timeSeriesMetric is MoneyAdTimeSeriesMetric =>
  isMoneyUnitMetric(timeSeriesMetric.unit);

export const isPercentageAdTimeSeriesMetric = (
  timeSeriesMetric: AdTimeSeriesMetric,
): timeSeriesMetric is PercentageAdTimeSeriesMetric =>
  isPercentageUnitMetric(timeSeriesMetric.unit);

export const isCountAdTimeSeriesMetric = (
  timeSeriesMetric: AdTimeSeriesMetric,
): timeSeriesMetric is CountAdTimeSeriesMetric =>
  isCountUnitMetric(timeSeriesMetric.unit);

export const isMoneyAdTimeSeriesMetricDataPoint = (
  dataPoint: AdTimeSeriesMetricDataPoint,
): dataPoint is MoneyAdTimeSeriesMetricDataPoint =>
  isMoneyUnitMetric(dataPoint.unit);

export const isPercentageAdTimeSeriesMetricDataPoint = (
  dataPoint: AdTimeSeriesMetricDataPoint,
): dataPoint is PercentageAdTimeSeriesMetricDataPoint =>
  isPercentageUnitMetric(dataPoint.unit);

export const isMoneyUnitMetric = (unit: MetricUnit): unit is MoneyMetricUnit =>
  unit === 'money';

export const isPercentageUnitMetric = (
  unit: MetricUnit,
): unit is PercentageMetricUnit => unit === 'percentage';

export const isCountUnitMetric = (unit: MetricUnit): unit is CountMetricUnit =>
  unit === 'count';

export const isComputedAdMetricName = (
  name: AdMetricName,
): name is ComputedAdMetricName => computedAdMetricNames.includes(name);

export const isRawAdMetricName = (
  name: AdMetricName,
): name is RawAdMetricName => rawAdMetricNames.includes(name);
