import { chartThemes } from './chart.constants';
import type { ChartConfig } from './chart.types';

const chartConfigToChartStyleInnerHtml = (
  id: string,
  config: ChartConfig,
): string | undefined => {
  const colorConfig = Object.entries(config).filter(
    ([_, item]) => item.theme ?? item.color,
  );

  if (colorConfig.length === 0) {
    return undefined;
  }

  return Object.entries(chartThemes)
    .map(([theme, prefix]) => {
      const cssProperties = colorConfig
        .map(([key, itemConfig]) => {
          const color =
            itemConfig.theme?.[theme as keyof typeof itemConfig.theme] ??
            itemConfig.color;

          return color ? `--color-${key}: ${color};` : undefined;
        })
        .filter((prop) => prop !== undefined);

      return `${prefix} [data-chart=${id}] {
      ${cssProperties.map((prop) => `  ${prop}`).join('\n')}
      }`;
    })
    .join('\n');
};

const getPayloadConfigFromPayload = (
  config: ChartConfig,
  payload: unknown,
  key: string,
) => {
  if (typeof payload !== 'object' || payload === null) {
    return undefined;
  }

  const payloadPayload =
    'payload' in payload &&
    typeof payload.payload === 'object' &&
    payload.payload !== null
      ? payload.payload
      : undefined;

  let configLabelKey: string = key;

  if (
    key in payload &&
    typeof payload[key as keyof typeof payload] === 'string'
  ) {
    configLabelKey = payload[key as keyof typeof payload] as string;
  } else if (
    payloadPayload &&
    key in payloadPayload &&
    typeof payloadPayload[key as keyof typeof payloadPayload] === 'string'
  ) {
    configLabelKey = payloadPayload[
      key as keyof typeof payloadPayload
    ] as string;
  }

  return configLabelKey in config ? config[configLabelKey] : config[key];
};

export { chartConfigToChartStyleInnerHtml, getPayloadConfigFromPayload };
