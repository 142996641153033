import { createDateRange } from '@growthlytic/shared-common';
import {
  useGetAdAccountEntityQuery,
  useGetAdEntityQuery,
  useGetAdMetricsQuery,
} from '@growthlytic/web-ads';
import { GetAdMetricsPayload } from '@growthlytic/web-shared-common';
import { useGetCurrentFacebookUserQuery } from '@growthlytic/web-users';
import {
  differenceInCalendarDays,
  eachMonthOfInterval,
  endOfMonth,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { useDashboardAdSelectionFilters } from './use-dashboard-ad-selection-filters';
import { useDateRangeFilters } from './use-date-range-filters';
import type { useFacebookApiKey } from './use-facebook-api-key';

type UseDashboardOverviewPageProps = {
  facebookApiKey?: ReturnType<typeof useFacebookApiKey>['state']['value'];
  selectedAdAccounts: ReturnType<
    typeof useDashboardAdSelectionFilters
  >['selectedAdAccounts'];
  selectedDateRange: ReturnType<
    typeof useDateRangeFilters
  >['selectedDateRange'];
};

export const useDashboardOverviewPageEntities = ({
  facebookApiKey,
  selectedAdAccounts,
  selectedDateRange,
}: UseDashboardOverviewPageProps) => {
  const getCurrentFacebookUserQuery = useGetCurrentFacebookUserQuery(
    { accessToken: facebookApiKey ?? '' },
    { enabled: facebookApiKey !== undefined && facebookApiKey !== '' },
  );

  const getAdAccountEntityQuery = useGetAdAccountEntityQuery(
    {
      accessToken: facebookApiKey ?? '',
      userId: getCurrentFacebookUserQuery.data?.id ?? '',
    },
    { enabled: getCurrentFacebookUserQuery.isSuccess },
  );

  const getAdEntityQuery = useGetAdEntityQuery(
    {
      accessToken: facebookApiKey ?? '',
      adAccountIds: selectedAdAccounts.map((item) => item.id),
    },
    { enabled: selectedAdAccounts.length > 0 },
  );

  const getAdTimeSeriesMetricQuery = useGetAdMetricsQuery(
    {
      accessToken: facebookApiKey ?? '',
      adAccountIdToAdIds: selectedAdAccounts.reduce(
        (obj, item) => {
          obj[item.id] = getAdEntityQuery.data?.byAdAccountId[item.id] ?? [];
          return obj;
        },
        {} as GetAdMetricsPayload['adAccountIdToAdIds'],
      ),
      adTreeLevel: 'root',
      dateRanges: [
        createDateRange({
          since: selectedDateRange.since,
          until: selectedDateRange.until,
        }),
        createDateRange({
          since: subDays(
            selectedDateRange.since,
            differenceInCalendarDays(
              new Date(selectedDateRange.until),
              new Date(selectedDateRange.since),
            ) + 1,
          ),
          until: subDays(selectedDateRange.since, 1),
        }),
      ],
    },
    { enabled: getAdEntityQuery.isSuccess },
  );

  const getMonthlyAdTimeSeriesMetricQuery = useGetAdMetricsQuery(
    {
      accessToken: facebookApiKey ?? '',
      adAccountIdToAdIds: selectedAdAccounts.reduce(
        (obj, item) => {
          obj[item.id] = getAdEntityQuery.data?.byAdAccountId[item.id] ?? [];
          return obj;
        },
        {} as GetAdMetricsPayload['adAccountIdToAdIds'],
      ),
      adTreeLevel: 'root',
      dateRanges: eachMonthOfInterval({
        start: subMonths(new Date(), 6),
        end: subMonths(new Date(), 1),
      }).map((dateString) => ({
        since: startOfMonth(new Date(dateString)),
        until: endOfMonth(new Date(dateString)),
      })),
    },
    { enabled: getAdTimeSeriesMetricQuery.isSuccess },
  );

  return {
    adAccountEntityQuery: getAdAccountEntityQuery,
    adEntityQuery: getAdEntityQuery,
    adTimeSeriesMetricQuery: getAdTimeSeriesMetricQuery,
    getCurrentFacebookUserQuery: getCurrentFacebookUserQuery,
    monthlyAdTimeSeriesMetricQuery: getMonthlyAdTimeSeriesMetricQuery,
  } as const;
};
